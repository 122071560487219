import * as Yup from 'yup'

export interface ICreateTransaction {
  address: string
  to: string
  amount: string
}
const inits: ICreateTransaction = {
  address: '',
  to: '',
  amount: '',
}

const createTransactionSchemas = [
  Yup.object({
    to: Yup.string().required().label('Transaction destiny address'),
    amount: Yup.number().min(0).required().label('Transaction amount'),
  }),
  Yup.object({}),
]

export {createTransactionSchemas, inits}
