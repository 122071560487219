import React, { useState } from 'react';
import { OptionType } from '../../../components/CustomStaticSelect/CustomStaticSelect';
import { useLazySearchQuery } from '../services/launchpadService';
import { useDebounce } from '../../../_metronic/helpers';
import useSearch from '../../../hooks/useSearch';
import { ListResponse } from '../../../resources/ListResponse';
import { LaunchpadResponseModel } from '../resources/launchpad/LaunchpadResponseModel';
import { BondingCurveListItem } from '../components/BondingCurveListItem';
import { CustomLoading } from '../../../components/Loading/Loading';
import { PaginationPages } from '../../../components/Pagination/PaginationPages';

const renderResult = (data: ListResponse<any> | null) => {
    return data ? <div className="d-flex gap-1 mt-5">
        {
            data.data.map((d: LaunchpadResponseModel) => <BondingCurveListItem launchpad={d} className='col-6 col-sm-4 col-md-3 ' />)}
    </div> : <></>;
}
const AdvancedSearchWrapper: React.FC = () => {
    const [text, setText] = useState('');
    const [fetchSearchData, _] = useLazySearchQuery();
    const debouncedQuery = useDebounce(text, 3000);
    const pageSize=15
    const [page, setPage] = useState(1);


    const { data, loading, error, resultContent } = useSearch({
        page,
        pageSize,
        query: text,
        fetchData: (params) => fetchSearchData({ ...params }).unwrap(),
        renderResult: (data) => renderResult(data),
    });

/*
    const statusOptions: OptionType[] = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
        { value: 'pending', label: 'Pending' },
    ];

    const orderOptions = [
        { value: 'latest', label: 'Latest' },
        { value: 'oldest', label: 'Oldest' },
    ];

    const handleDropdownChange = (selectedOption: OptionType | null) => {
        console.log('Selected:', selectedOption);
    };
*/

    return (
        <div className="container-fixed">
            <div className="flex items-center justify-between gap-2 flex-wrap mb-7">
                <h3 className="text-md text-gray-900 font-medium">Advanced search</h3>
                <div className="d-flex justify-content-between align-items-center my-5">

                    <div className="flex">
                        <div className="input-group border border-0">
                                <span className="input-group-text form-control-solid  border border-0"> <i className="bi bi-search"></i></span>
                            

                            <input type="text" value={text} onChange={(event) => setText(event.target.value)} className="form-control  border border-0" placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1" />
                            {loading && <span className="input-group-text form-control-solid  border border-0"> <CustomLoading className='p-0' size={10}/></span>}
                            
                        </div>

                    </div>
                    {/* 
                    <div className='d-flex align-items-end gap-2'>

                        <CustomStaticSelect
                            initialIndex={0}
                            options={statusOptions}
                            onChange={handleDropdownChange}
                        />
                        <CustomStaticSelect
                            initialIndex={0}
                            options={orderOptions}
                            onChange={handleDropdownChange}
                        />


                        <button className="btn btn-sm btn-outline btn-primary">
                            <i className="bi bi-filter"></i> Filters
                        </button>
                    </div> */}
                </div>
            </div>
            <div className='border border-bottom-1 border-solid'></div>
            <div  style={{minHeight: "60vh"}}>
                {/* Reemplaza con tu BondingCurveListItem */}
                { resultContent}
                {error && !data && <div>Result not found</div>}
            </div>
            <div className='d-flex justify-content-end'>
        {data && 
            <PaginationPages
            page={data.page}
            perPage={data.per_page}
            total_pages={data.total_pages}
            totalResults={data.total}
            onPageChange={setPage}
            />
        }
                </div>
        </div>

    );
};

export default AdvancedSearchWrapper;
