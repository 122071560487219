
import deMessages from './de.json';
import enMessages from './en.json';
import esMessages from './es.json';
import ptMessages from './pt.json';
import frMessages from './fr.json';
import jaMessages from './ja.json';
import zhMessages from './zh.json';

const resources = {
  de: { fusywallet: deMessages },
  en: { fusywallet: enMessages },
  es: { fusywallet: esMessages },
  fr: { fusywallet: frMessages },
  ja: { fusywallet: jaMessages },
  zh: { fusywallet: zhMessages },
  pt: { fusywallet: ptMessages },
};

export default resources