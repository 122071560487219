import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BondingCurveSmallListItem } from "../components/BondingCurveSmallListItem";
import useLaunchpadPaginated from "../hooks/useLaunchpadPaginated";
import { PaginationPages } from "../../../components/Pagination/PaginationPages";
import { CustomLoading } from "../../../components/Loading/Loading";
import { useWeb3Context } from "../../../contexts/Web3";
import { BondingCurveListItem } from "../components/BondingCurveListItem";
import { usePrice } from "../../../contexts/PriceContext";

const AdminDashboard: React.FC = () => {
  // Usamos useParams para obtener el parámetro 'category' de la ruta
  const { category } = useParams<{ category: string }>();
  const { accountAddress, isConnected } = useWeb3Context();
  const { price } = usePrice();

  const pageSize = 15;
  const { page, setPage, loading, launchpadPage, notValid } =
    useLaunchpadPaginated(category!, price, pageSize, true);
    
  if (!accountAddress || !isConnected) return <></>;
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <div>
        <h1>Owned Projects</h1>
        <div className="row" style={{}}>
          {loading ? (
            <CustomLoading />
          ) : (
            launchpadPage.data // Filtrar los juegos por categoría
              .map((l) => (
                <BondingCurveListItem
                  launchpad={l}
                  className="col-3 col-md-4 pb-4"
                  playable={category == "Playable"}
                />
              ))
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end flex-1">
        <PaginationPages
          page={launchpadPage.page}
          perPage={launchpadPage.per_page}
          total_pages={launchpadPage.total_pages}
          totalResults={launchpadPage.total}
          onPageChange={setPage}
        />
      </div>
    </div>
  );
};

export default AdminDashboard;
