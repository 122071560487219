import React, { useMemo } from 'react';

interface PaginationProps {
  page: number;
  total_pages: number;
  perPage: number;
  totalResults: number;
  onPageChange: (newPage: number) => void;
  withLabel?: boolean;
}

const PaginationPages: React.FC<PaginationProps> = ({ page, total_pages, perPage ,totalResults,onPageChange, withLabel=true }) => {
    const startIndex = (page - 1) * perPage + 1;
  const endIndex = Math.min(page * perPage, totalResults);
  
  const generatePageNumbers = () => {
    const pages = [];
    const currentPage = page;
    const totalPages = total_pages;

    // Display three pages before and after the current page
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 2);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === page ? 'active' : ''}`}>
          <span  className='page-link' onClick={() => onPageChange(i)}>
            {i}
          </span>
        </li>
      );
    }

    // Add ellipsis if there are more pages
    if (endPage < totalPages) {
      pages.push(
        <li key="ellipsis">
          <span className="page-numbers" onClick={() => onPageChange(endPage+1)}>...</span>
        </li>
      );
    } 

    return pages;
  };

  const pageNumbers = useMemo(() => generatePageNumbers(), [page, total_pages, perPage, totalResults])
  // TODO: i18n
  return (
    <div className='d-flex flex-stack flex-wrap'>
      {withLabel && 
          <div className='fs-6 fw-bold text-gray-700'>
            Showing {startIndex} to {endIndex} of {totalResults} entries
          </div>
          }
  
          <ul className='pagination px-2'>
            {page > 1 && 
            <li className='page-item previous'>
              <span onClick={() => onPageChange(page-1)} className='page-link'>
                <i className='previous'></i>
              </span>
            </li>
            }
  
            {pageNumbers.length > 1 && pageNumbers}

            {page < total_pages && 
            <li className='page-item next'>
              <span onClick={() => onPageChange(page+1)} className='page-link'>
                <i className='next'></i>
              </span>
            </li>
            }
          </ul>
        </div>
    
  );
};

export {PaginationPages};
