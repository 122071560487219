import { useState, useEffect, useMemo } from "react";
import { useDebounce } from "../_metronic/helpers";

// Define the interface for search parameters
interface SearchParams {
  page: number;
  pageSize: number;
  query: string;
}

// Define the interface for the API response
interface SearchResponse<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
  resultContent: JSX.Element | null;
}

// Define the props for the custom hook
interface UseSearchProps<T> {
  page: number;
  pageSize: number;
  query?: string;
  fetchData: (params: SearchParams) => Promise<T>;
  renderResult: (data: T) => JSX.Element;  // Function to render data
}

function useSearch<T>({
  page,
  pageSize,
  query,
  fetchData,
  renderResult,
}: UseSearchProps<T>): SearchResponse<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const resultContent = useMemo<JSX.Element | null> (() => data ? renderResult(data) : null, [data])

  const debouncedQuery = useDebounce(query, 1000); // Debounce the query input for 2 seconds
  
  useEffect(() => {
    if (!debouncedQuery) {
        setData(null)
        return;}
    
    setLoading(true);
    setError(null);

    const params = { page, pageSize, query: debouncedQuery };

    fetchData(params)
      .then((response) => {
        setData(response);
        console.debug("Search response ", response)
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        setData(null)
      });
  }, [page, pageSize, debouncedQuery]);


  return { data, loading, error, resultContent };
}

export default useSearch;
