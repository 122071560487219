import { RefObject } from "react"

interface IVideoComponent {
    src: string | undefined
    videoRef?: RefObject<HTMLVideoElement>
    controls?:boolean
}

const VideoComponent:  React.FC<IVideoComponent>  = ({src, videoRef, controls = true}) => {
    return <div >
          {src &&
            <video className="rounded" ref={videoRef} width="100%" controls={controls}>
              <source src={src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          } 
    </div>
}

export {VideoComponent}