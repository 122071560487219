import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Web3Context from './Web3Context'
import Web3 from 'web3'
import { useAccount, useConnect, useSignMessage } from 'wagmi'
import { injected } from 'wagmi/connectors';
import { toast } from 'react-toastify'
import FusySwapFacade from '@fusyona/fusy-swap/offchain/fusy-swap-facade'
import { isSupportedChainId, SupportedChainId } from '@fusyona/fusy-swap/offchain/types'
import { newFacadeFromChainIdAndProvider } from '@fusyona/fusy-swap/offchain/fusy-swap-facade-factory'
import { RootState } from '../../setup'
import { useAppSelector } from '../../hooks/hooks'
import { chainInfo } from '../../resources/chains'
import { notifyError } from '../../utils/toasts'
import { BrowserProvider, JsonRpcProvider } from 'ethers'

const Web3ContextProvider: FC<React.PropsWithChildren<{}>> = (props) => {
  const { address, isConnected } = useAccount()
  const [isLoading, setIsLoading] = useState(false)
  const [accountAddress, setAccountAddress] = useState('')
  const networkId = useAppSelector((state: RootState) => state.auth.network)
  const networkInfo = useMemo(() => chainInfo[networkId], [networkId])
  const { connect } = useConnect();

  const _connect = () => connect({ chainId: networkInfo.chainId, connector: injected() });

  const initialProvider = useCallback(() => {
    console.debug("PROV TEST", "JsonRpcProvider", networkInfo.url);
    return new JsonRpcProvider(networkInfo.url);
  }, [networkInfo.url]);

  const currentProvider = () => {
    console.debug("PROV TEST", "BrowserProvider", (window as any).ethereum)
    return new BrowserProvider((window as any).ethereum)
  }

  const [provider, setProvider] = useState<any>(isConnected ? currentProvider() :
    initialProvider())
  const [facade, setFacade] = useState<FusySwapFacade | undefined>(undefined)
  const { signMessageAsync } = useSignMessage()

  const signMessage = useCallback(
    async (message: string): Promise<string> => {
      try {
        const signature = await signMessageAsync({ message });
        return signature;
      } catch (error) {
        throw new Error('Error signing the message: ' + (error as Error).message);
      }
    },
    [signMessageAsync] // Dependency ensures memoization
  );


  // Actualizar el provider cuando cambia la red
  useEffect(() => {
    setAccountAddress(address ?? '')
    console.debug("aaa isCOnnected: ", isConnected, !!networkInfo)
    if (networkInfo) {
      setProvider(isConnected ? currentProvider() : initialProvider())
    }
  }, [networkInfo, address, isConnected])

  // Actualizar el facade cuando cambia la red o la conexión
  useEffect(() => {
    if (networkInfo) {
      try {
        const chainIdStr = networkInfo.chainId.toString() as SupportedChainId
        if (isSupportedChainId(chainIdStr)) {
          const facadeInstance = newFacadeFromChainIdAndProvider(chainIdStr, networkInfo.url)
          setFacade(facadeInstance)
        } else {
          throw new Error('Not Supported chainId')
        }
      } catch (error) {
        notifyError((error as Error).message || 'An error occurred while setting facade')
      }
    } else {
      toast.warn('Chain not found/supported')
    }
  }, [networkInfo, isConnected])

  useEffect(() => {
    if (isConnected) {
      console.debug("PROV start")
      setProvider(currentProvider())
    }
  }, [])
  
  return (
    <Web3Context.Provider
      value={{
        provider,
        setProvider,
        isConnected,
        setIsConnected: () => { },
        isLoading,
        setIsLoading,
        network: networkInfo,
        setNetwork: () => { }, // Implementa esta función si es necesario
        accountAddress,
        connect: _connect,
        signMessage,
        facade,
      }}
    >
      {props.children}
    </Web3Context.Provider>
  )
}

export default Web3ContextProvider
