import { MarketStatusResponse } from '@fusyona/erc20-launchpad/dist/src/zapp/types'
import { LaunchpadResponseModel } from './resources/launchpad/LaunchpadResponseModel';
import { LaunchpadType } from './resources/BCLauchpadItem';

export const mapToBCLaunchpadItem = ( marketStatus: MarketStatusResponse, model: LaunchpadResponseModel) => {
  return {
    ...model,
    ...marketStatus,
  } as  LaunchpadType
}


export function formatValue(value: number) {
  return value < 1 ? value.toFixed(8) : value.toFixed(2)
}

export function formatPosibleBigNumbers(marketCap: number) {
  // Define the threshold 
  const K = 1_000;        // 1 thousand
  const M = 1_000_000;    // 1 million
  const B = 1_000_000_000; // 1 billion
  const T = 1_000_000_000_000; // 1 trillion
  if (marketCap >= T) {
      return (marketCap / T).toFixed(2) + ' T'; // Trillions
  } else if (marketCap >= B) {
      return (marketCap / B).toFixed(2) + ' B'; // Billions
  } else if (marketCap >= M) {
      return (marketCap / M).toFixed(2) + ' M'; // Millions
  } else if (marketCap >= K) {
      return (marketCap / K).toFixed(2) + ' K'; // Thousands
  } else {
      return formatValue(marketCap); // Less than 1,000
  }
}