import { useState, useMemo, useEffect } from "react";
import { useWeb3Context } from "../../../contexts/Web3";
import { ListResponse } from "../../../resources/ListResponse";
import { LaunchpadResponseModel } from "../resources/launchpad/LaunchpadResponseModel";
import { useGetAllLaunchpadsQuery, useGetListedLaunchpadsQuery, useOwnerLaunchpadsQuery } from "../services/launchpadService";
import GetBCLaunchpadListInfo from "../web3/GetBCLaunchpadListInfo";
import { FetchLaunchpadRequest } from "../resources/launchpad/FetchLaunchpadRequest";
import { LaunchpadType } from "../resources/BCLauchpadItem";
import { LaunchpadOwnerRequest } from "../resources/launchpad/LaunchpadRequestModel";

const useLaunchpadPaginated = (category: string, price?: number, pageSize = 9, owner: boolean = false) => {
  const [page, setPage] = useState<number>(1);
  const { network, provider, accountAddress } = useWeb3Context();
  const [loading, setLoading] = useState<boolean>(false);

  const [launchpadPage, setLaunchpadPage] = useState<ListResponse<LaunchpadType>>({
    data: [],
    page: page,
    per_page: pageSize,
    total: 0,
    total_pages: 0,
  });
  const requestProps: FetchLaunchpadRequest | LaunchpadOwnerRequest = useMemo(() => { return { network: network?.name, page, pageSize } }, [page, network])

  const { data: launchpads, isLoading, isError, isSuccess, refetch } = owner && network && accountAddress ? 
  useOwnerLaunchpadsQuery ({ownerAddress: accountAddress, ...requestProps}) : 

  (category == 'Playable')
    ? useGetListedLaunchpadsQuery(requestProps)
    : useGetAllLaunchpadsQuery({ ...requestProps, category });

  const notValid = useMemo(() => isLoading || isError || (isSuccess && launchpads?.data.length === 0), [isLoading, isSuccess, isError]);

  const formatLaunchpadPage = async (launchpads: ListResponse<LaunchpadResponseModel>) => {
    const perPage = Math.min(launchpads.total, pageSize);
    const filteredLaunchpads = launchpads.data.filter(s => s.address && s.tokenAddress);

    // Extraer solo los resultados exitosos 
    let successfulItems = filteredLaunchpads;
    if (category != "Playable") {
      successfulItems = await GetBCLaunchpadListInfo(provider, filteredLaunchpads, network, price!);
    }

    // Actualiza el estado de launchpadPage
    setLaunchpadPage(prevState => ({
      ...prevState,
      data: successfulItems,
      total: launchpads.total,
      per_page: perPage,
      total_pages: launchpads.total_pages,
      page,
    }));

  };

  useEffect(() => {
    refetch();
  }, [page, refetch, provider]);

  useEffect(() => {
    if (launchpads && price) {

      setLoading(true);
      formatLaunchpadPage(launchpads).then(() =>
        setLoading(false));
    }
  }, [launchpads]);

  useEffect(() => {
    if (launchpads && price) {

      formatLaunchpadPage(launchpads)
    }
  }, [price]);

  return {
    page,
    setPage,
    loading,
    launchpadPage,
    notValid,
  };
};

export default useLaunchpadPaginated;