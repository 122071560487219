import React from "react";
import ReactMarkdown from "react-markdown";

interface Step {
  title: string;
  description: string;
}

const steps: Step[] = [
  {
    title: "Step 1: Create a New Bot on Telegram",
    description: `1. Open Telegram and search for the \`@BotFather\` bot, which is the official Telegram bot used to create and manage bots. 
Start a chat with \`@BotFather\` and type \`/newbot\` to initiate the bot creation process. 
Follow the prompts to:
- **Name your bot** (this will be the display name).
- **Choose a username** for the bot, which must end in "bot" (e.g., ExampleBot or Example_bot).
- After creating the bot, \`@BotFather\` will generate a **Bot Token**. This token will have the format \`ClientId:code\`. 
Copy this token, it will serve as your **Secret Key**. 
2. Identify the Client Id
- The ClientId is the part before the colon (:). For example, if the Bot Token is \`123456789:ABCDEF\`, the **ClientId** is \`123456789\`.`,
  },
  {
    title: "Step 2: Configure the Bot's Domain",
    description: `1. Use the following **Domain URL** provided by us to configure your bot in Telegram:
- Domain URL: \`[static_web_url]\`
2. In Telegram, after creating your bot, you need to configure this URL for your bot:
- Open a chat with \`@BotFather\`
- Type \`/setdomain\` and paste the Domain URL when prompted.`,
  },
  {
    title: "Step 3: Fill in the Form Fields",
    description: `- **Telegram Bot Name**: Enter the username of your bot (e.g., @examplebot).
- **ClientId**: Extract the **ClientId** from the Bot Token as explained in Step 1.
- **Secret Key**: Paste the full Bot Token (including \`ClientId:code\`) generated by @BotFather.`,
  },
  {
    title: "Step 4: Submit the Form",
    description:
      "After entering the required fields (Bot Name, ClientId, and Secret Key), click **Submit**.",
  },
];

const TelegramBotHelp: React.FC<{webUrl: string}> = ({webUrl}) => {
  return (
    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
      {/* begin::Wrapper */}
      <div className="d-flex flex-column flex-grow-1">
        {/* begin::Content */}
        <h4 className="text-gray-900 fw-bold mb-4">Telegram Bot Setup Guide</h4>
        <div className="fs-6 text-gray-700">
          {steps.map((step, index) => (
            <div key={index} className="mb-4">
              <h5 className="fw-semibold">{step.title}</h5>
              <ReactMarkdown>{step.description.replace('[static_web_url]', webUrl)}</ReactMarkdown>
            </div>
          ))}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Wrapper */}
    </div>
  );
};

export default TelegramBotHelp;
