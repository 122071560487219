import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import CreateMissionForm from "./CreateMissionForm";
import { Mission } from "../../resources/game/missions";
import { Formik } from "formik";
import { initialValues, missionSchema } from "./CreateMissionFormHelper";

type MissionModalProps = {
  show: boolean;
  handleFormSubmit: (values: Mission) => void;
  handleClose: () => void;
  data?: Mission;
};

const MissionModal: FC<MissionModalProps> = ({
  data,
  show,
  handleFormSubmit,
  handleClose,
}) => {
  return (
    <Modal show={show} onHide={() => handleClose()} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {data?.missionId ? "Update" : "Create New"} Mission
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={missionSchema}
          onSubmit={handleFormSubmit}
        >
          <CreateMissionForm 
            data={data || initialValues} />
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default MissionModal;
