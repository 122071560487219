import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getCurrentUrl, toAbsoluteUrl } from "../../../../helpers";
import { MenuInnerWithSub } from "../MenuInnerWithSub";
import { MenuItem } from "../MenuItem";
import { useAppSelector } from "../../../../../hooks/hooks";
import { useLayout } from "../../../core";
import Topbar from "../Topbar";
import clsx from "clsx";
import { toolbarButtonMarginClass } from "../../../../../resources/Constants";
import NetworkSelector from "../../../../../components/NetworkSelector";
export function MenuInnerWithLogo() {
  const { config } = useLayout();
  const token = useAppSelector((state) => state.auth.token);

  const isAuthorized = Boolean(token);
  const { t } = useTranslation();
  const { t: tf } = useTranslation("fusywallet");
  return (
    <>
      <div
        className="aside-logo d-flex align-items-center justify-content-center pe-md-10"
        id="kt_aside_logo"
      >
        <Link to="/">
          <img
            alt="Logo"
            className="h-60px logo mt-2"
            src={toAbsoluteUrl("/media/logos/logo.png")}
          />
        </Link>
      </div>
      <div className="d-flex d-md-none flex-column gap-2 align-items-center mt-2 mb-6">
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          <w3m-button />
        </div>
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          <NetworkSelector />
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column align-items-center mx-auto w-100 justify-content-center gap-1">
        <MenuItem
          title={t("MENU.EXPLORE")}
          fontIcon="bi bi-search"
          to="/explore"
          extraCondition={(pathname, to) =>
            getCurrentUrl(pathname).indexOf("/Playable") == -1
          }
          hasBullet={false}
        />
        <MenuItem
          title={t("MENU.CREATE_NEW_LAUNCHPAD")}
          fontIcon="bi bi-plus"
          to="/new-bonding-curve"
          hasBullet={false}
        />
        <MenuItem
          title={t("MENU.PLAYABLE")}
          fontIcon="bi bi-controller"
          to="/explore/Playable"
          hasBullet={false}
        />
        <MenuItem
          title={tf("MENU.WALLET")}
          to="/wallet"
          fontIcon="bi bi-wallet"
          hasBullet={false}
        ></MenuItem>
      </div>
    </>
  );
}
