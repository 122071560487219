/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import BigNumber from 'big.js'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next';

import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import { useNavigate } from 'react-router'
import { UserCurrency } from '../../../../resources/wallet/UserCurrency';
import { useGetFullAddressPaginatedQuery } from '../../../../services/walletService';
import { useAppDispatch } from '../../../../../../hooks/hooks';
import { setSelectedCurrency } from '../../../../slices/currencySlice';


type Props = {
  className: string
}

function BtnCellRenderer() {}

BtnCellRenderer.prototype.init = function(params: any) {


  this.params = params;

  this.eGui = document.createElement('div');
  this.depositButton = document.createElement('button');
  this.depositButton.innerHTML = params?.labelDeposit;
  this.depositButton.classList.add("btn");
  this.depositButton.classList.add("btn-light-primary");
  this.depositButton.classList.add("deposit-button");
  this.depositButton.classList.add("btn-sm");
  this.depositButton.classList.add("me-2");

  this.withdrawButton = document.createElement('button');
  this.withdrawButton.innerHTML = params?.labelWithdraw;
  this.withdrawButton.classList.add("btn");
  this.withdrawButton.classList.add("btn-light-warning");
  this.withdrawButton.classList.add("withdraw-button");
  this.withdrawButton.classList.add("btn-sm");


  this.eGui.appendChild(this.depositButton);
  this.eGui.appendChild(this.withdrawButton);
  this.btnClickedHandler = this.btnClickedHandler.bind(this);
  this.depositButton.addEventListener('click', this.btnClickedHandler);
  this.withdrawButton.addEventListener('click', this.btnClickedHandler);
}

BtnCellRenderer.prototype.getGui = function() {
  return this.eGui;
}

BtnCellRenderer.prototype.destroy = function() {
  this.eGui.removeEventListener('click', this.btnClickedHandler);
}

BtnCellRenderer.prototype.btnClickedHandler = function(event: any) {
  if(event?.target?.className.includes("deposit-button")){
    this.params.deposit(this.params.value );
  }else if(event?.target?.className.includes("withdraw-button")){
    this.params.withdraw(this.params.value );
  }
}

function SymbolCellRenderer() {}

SymbolCellRenderer.prototype.init = function(params: any) {


  this.params = params;

  this.eGui = document.createElement('img');
  this.eGui.src = this.params?.value;
  this.eGui.style.width = "50px";
  this.eGui.style.height = "50px";

  this.btnClickedHandler = this.btnClickedHandler.bind(this);
  this.eGui.addEventListener('click', this.btnClickedHandler);
}

SymbolCellRenderer.prototype.getGui = function() {
  return this.eGui;
}

SymbolCellRenderer.prototype.destroy = function() {
  this.eGui.removeEventListener('click', this.btnClickedHandler);
}

SymbolCellRenderer.prototype.btnClickedHandler = function(event: any) {
    this.params.click(this.params.value );
}

const TableWidgetWallet: React.FC<Props> = ({className}) => {

  const {t} = useTranslation("fusywallet")


  function calculateTotal(currency:UserCurrency){
    const available = new BigNumber(currency.balance.available);
    const in_orders = new BigNumber(currency.balance.in_orders);
    const total = available.add(in_orders);
    const decimal = currency.cryptocurrency.decimals;//Math.min(8, currency.cryptocurrency.decimals);
    return total.toFixed() !== '0' ? numeral(total.div(10 ** decimal).toFixed()).format('0,0.00[000000]') : '0.00000000' ;
      
}

function calculateAvailable(currency: UserCurrency){
   const available = new BigNumber(currency.balance.available)
   const decimal = currency.cryptocurrency.decimals;//Math.min(8, currency.cryptocurrency.decimals)

   return available.toFixed() !== '0'
           ? numeral(available.div(10 ** decimal).toFixed()).format('0,0.00[000000]')
           : '0.00000000';
   
  }

function calculateInOrder(currency: UserCurrency){
  const in_orders = new BigNumber(currency.balance.in_orders)
  const decimal = currency.cryptocurrency.decimals;//Math.min(8, currency.cryptocurrency.decimals)

  return in_orders.toFixed() !== '0'
          ? numeral(in_orders.div(10 ** decimal).toFixed()).format('0,0.00[000000]')
          : '0.00000000';
}

   let navigate = useNavigate()
   const dispatch = useAppDispatch()

   //const {data} = useGetFullAddressQuery()
  
    const [currentPage, setCurrentPage] = useState(1)
    const {data} = useGetFullAddressPaginatedQuery(currentPage);

   const onWithdrawalSelect = (currency: any) => {
     if (currency !== undefined) {
       dispatch(setSelectedCurrency({selectedCurrency: currency}))
     }
     navigate('./withdrawals')
   }

   const onDepositSelect = (currency: any) => {
     if (currency !== undefined) {
       dispatch(setSelectedCurrency({selectedCurrency: currency}))
     }
     navigate('./deposits')
   }

    
   const [columnDefs] = useState<{
    headerName: string;
    field: any;
    sortable: boolean;
    filter?: string;
    cellRenderer?: string;
    cellRendererParams?: any;
    minWidth?: number;
    maxWidth?: number;
    flex?: number;
}[]>(
    [
      {headerName: t('WALLET.TABLE.IMAGE'), field: "image", sortable: false,
      minWidth: 80,
      maxWidth: 80,
      flex: 2,
      cellRenderer: 'symbolCellRenderer', 
      cellRendererParams: {
        clicked: (field: any) => {
          
        }
      } },
      {headerName: t('WALLET.TABLE.SYMBOL'), field: "symbol", sortable: true,
      minWidth: 90,
      maxWidth: 90,
      flex: 2,
      filter: 'agTextColumnFilter'  },
      {headerName: t('WALLET.TABLE.NAME'), field: "name", sortable: true,
      filter: 'agTextColumnFilter', flex: 1  },
      {headerName: t('WALLET.TABLE.TOTAL'), field: "total", sortable: true ,
      filter: 'agTextColumnFilter' , flex: 1},
      {headerName: t('WALLET.TABLE.AVALIABLE'), field: "avaliable", sortable: true,
      filter: 'agTextColumnFilter' , flex: 1},
      {headerName: t('WALLET.TABLE.IN_ORDER'), field: "inOrder", sortable: true,
    filter: 'agTextColumnFilter', flex: 1 },
      {headerName: t('WALLET.TABLE.ACTIONS'), field: "actions", sortable: false,
      minWidth: 200,
      maxWidth: 200,
      flex: 2,
      cellRenderer: 'btnCellRenderer', 
      cellRendererParams: {
        labelDeposit: t('WALLET.DEPOSIT'),
        labelWithdraw: t('WALLET.WITHDRAW'),
        deposit: (field: any) => {
          onDepositSelect(field);
        },
        withdraw: (field: any) => {
          onWithdrawalSelect(field);
        }
      },},
    ]
   );
   const [rowData,setRowData] = useState<{
    image: string;
    symbol: string;
    name: string;
    total: string;
    avaliable: string;
    inOrder: string;
    actions?: any;
}[]>(
    [	{image: "",symbol: "",name: "Toyota", total: "1000", avaliable: "35000", inOrder: "2000", actions: null},  ]
   );
   useEffect(() => {
     setRowData( !!data ? data?.list.map((currency, i) => ({
       image: currency.cryptocurrency.profileURL,
       symbol: `${currency.cryptocurrency.symbol}`,
       name: `${currency.cryptocurrency.label}`, 
       total: calculateTotal(currency), 
       avaliable: calculateAvailable(currency), 
       inOrder: calculateInOrder(currency), actions: currency})): [])
   }, [data]);

   

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div
        className='ag-theme-fusyona-dark'
        style={{
          height: '500px',
          width: '100%',
        }}
      >
        <AgGridReact
          components={{btnCellRenderer: BtnCellRenderer, symbolCellRenderer: SymbolCellRenderer}}
          defaultColDef={{sortable: true}}
          columnDefs={columnDefs}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          }
          overlayNoRowsTemplate={`<div class="d-flex justify-content-center w-100">
          <div
            class="spinner-border""
            style="width: 3rem; height: 3rem"
            role="status"
          >
          </div>
        </div>`}
          rowData={rowData}
        ></AgGridReact>
      </div>
      {/* begin::Body */}
      {/* Pagination controls */}
      <div className='pagination'>
        <button
          className='btn btn-light-primary btn-sm me-4'
          onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className='card-label fw-bolder fs-7 mt-3 mb-1 ml-4 mr-4'>Page: {currentPage}</span>
        <button
          className='btn btn-light-primary deposit-button btn-sm ml-6'
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export {TableWidgetWallet}
