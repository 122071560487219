import { IPaginationRequest } from "../../../../resources/Pagination";
import { SignedRequest } from "../../../../resources/SignedRequest";

export enum actionTags {
    FollowX = 'FollowTwitter',
    ValidateUsername = 'ValidateUsername'
  }
// Types
export interface Metadata {
    actionTag: actionTags;
    targetQuantity: number;
    isComplete: boolean;
    missionButtonText: string;
    objectiveLines: number;
    logoUrl: string;
    apiUrl: string;
  }
  
  export interface Mission {
    missionId?: string;
    title: string;
    description: string;
    type: string;
    reward:{
      coins: number;
      [key: string]: number 
      };
    metadata: Metadata;
    userId?: string;
    frequency: string;
    requirements:  { [key: string]: any };
  }
  
  export interface IAddUpdMission extends Mission, SignedRequest {
   gameId: string;
  }

  export interface IMissionsRequest extends IPaginationRequest, SignedRequest {
      gameId: string;
 }


 export interface IAddTwitterUsername extends  SignedRequest {
  gameId: string;
  twitterUsername: string
}

export interface IAddTelegramBot extends  SignedRequest {
  gameId: string;
  telegramBotName: string
  telegramBotClientId: string
  telegramBotClientSecret: string
}


export interface PaginatedGameMissions {
  missions: Mission[],
  currentPage: string, 
  pageSize: number, 
  totalCount: number, 
  totalPages: number
}

export interface IDeleteMission extends SignedRequest {
  gameId: string,
  missionId:string
}