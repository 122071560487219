import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/dist/query';
import createSagaMiddleware from "redux-saga";
import rootSaga from '../sagas/rootSaga'
import authReducer from '../slices/authSlice';
import { profileService } from '../../services/profileService';
import { launchpadService } from '../../modules/BondingCurve/services/launchpadService'
import { universeService } from '../../modules/BondingCurve/services/universeService';
import { gameService } from '../../modules/BondingCurve/services/gameService';
import { socialService } from '../../modules/BondingCurve/services/socialService';
import { erc20Service } from '../../modules/BondingCurve/services/erc20Service';
import { paymentService } from '../../modules/FusyWallet/services/paymentService';

import { walletService } from '../../modules/FusyWallet/services/walletService';
import currencyReducer from '../../modules/FusyWallet/slices/currencySlice'

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    [launchpadService.reducerPath]: launchpadService.reducer,
    [universeService.reducerPath]: universeService.reducer,
    [gameService.reducerPath]: gameService.reducer,
    [socialService.reducerPath]: socialService.reducer,
    [erc20Service.reducerPath]: erc20Service.reducer,
    [walletService.reducerPath]: walletService.reducer,
    [profileService.reducerPath]: profileService.reducer,
    [paymentService.reducerPath]: paymentService.reducer,
    auth: authReducer,
    currency: currencyReducer
  },
  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(launchpadService?.middleware)
      .concat(universeService?.middleware)
      .concat(gameService?.middleware)
      .concat(socialService?.middleware)
      .concat(erc20Service?.middleware)
      .concat(walletService?.middleware)
      .concat(profileService?.middleware)
      .concat(paymentService?.middleware)
      .concat(sagaMiddleware),
})
sagaMiddleware.run(rootSaga);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


