/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { CustomLoading } from "../../../../components/Loading/Loading";
import { Mission } from "../../resources/game/missions";

type Props = {
  missions: Mission[] | undefined;
  isLoading: boolean;
  disableActions: boolean;
  className?: string;
  handleEdit: (index: number) => void;

  handleDelete: (index: number) => void;
};

const ListMissions: React.FC<Props> = ({
  missions,
  isLoading,
  disableActions,
  handleDelete,
  handleEdit,
  className = "",
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">List Missions</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Boost your score and increase your position.
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bold text-muted bg-light">
                <th className="ps-4 min-w-300px rounded-start">Mission</th>
                <th className="min-w-125px">frequency</th>
                <th className="min-w-125px">reward</th>
                {/* <th className='min-w-150px'>Rating</th>*/}
                <th className="min-w-200px text-end rounded-end"></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {missions ? (
                missions.length > 0 ? (
                  missions.map((p, index) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px me-5">
                            <span className="symbol-label bg-light">
                              <img
                                src={p.metadata.logoUrl}
                                className="h-75 align-self-center"
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="d-flex justify-content-start flex-column">
                            <a
                              href="#"
                              className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                            >
                              {p.title}
                            </a>
                            <span className="text-muted fw-semibold text-muted d-block fs-7">
                              {p.description}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href="#"
                          className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                        >
                          {p.frequency}
                        </a>
                      </td>
                      <td>
                        <a
                          href="#"
                          className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                        >
                          {p.reward.coin || p.reward.coins}
                        </a>
                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span> */}
                      </td>
                      {/* 
                <td>
                  <div className='rating'>
                    <div className='rating-label me-2 checked'>
                      <i className='bi bi-star-fill fs-5'></i>
                    </div>
                    <div className='rating-label me-2 checked'>
                      <i className='bi bi-star-fill fs-5'></i>
                    </div>
                    <div className='rating-label me-2 checked'>
                      <i className='bi bi-star-fill fs-5'></i>
                    </div>
                    <div className='rating-label me-2 checked'>
                      <i className='bi bi-star-fill fs-5'></i>
                    </div>
                    <div className='rating-label me-2 checked'>
                      <i className='bi bi-star-fill fs-5'></i>
                    </div>
                  </div>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 mt-1'>
                    Best Rated
                  </span>
                </td>*/}
                      <td className="text-end">
                        <button
                          onClick={() => handleEdit(index)}
                          disabled={disableActions}
                          className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(index)}
                          disabled={disableActions}
                          className="btn btn-bg-light btn-color-muted btn-active-color-danger btn-sm px-4"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr aria-rowspan={3}>
                    <div className="py-3">No missions yet</div>
                  </tr>
                )
              ) : (
                isLoading && <CustomLoading />
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { ListMissions };
