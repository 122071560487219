import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { decodeToken } from 'react-jwt'
import { setCredentials, User } from '../setup/slices/authSlice'
import { PublicClientApplication } from '@azure/msal-browser'
import { RootState } from '../setup'
import { loginRequest, msalConfig } from '../authConfig'

const constructBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl, //'http://localhost:7071/api',// // // //'https://api.fusyona.com/nfts/v1',
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      headers.set('Ocp-Apim-Subscription-Key', `710e277524cc426baf6c8ef1eb930af5;product=starter`)
      headers.set('Ocp-Apim-Trace', `true`)
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  })

export const baseQueryWithReauthAndRetry = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  baseUrl: string,
  retry: number = 10
) => {
  let result: any = {}
  let ind = 0

  do {
    if (ind >= 2) {
      await sleep(1000 * (ind / 2))
    }
    result = await baseQueryWithReauth(args, api, extraOptions, baseUrl)
    ind = ++ind
  } while (
    (result?.meta?.response?.status === 500 || result?.meta?.response?.status === 404) &&
    ind < retry
  )
  return result
}

export const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  baseUrl: string
) => {
  const baseQuery = constructBaseQuery(baseUrl)
  let result = await baseQuery(args, api, extraOptions)
  if (result?.meta?.response?.status === 403 || result?.meta?.response?.status === 401) {
    const msalInstance = new PublicClientApplication(msalConfig)
    const accounts = msalInstance.getAllAccounts()
    const account = accounts[0] || null
    if (account) {
      const responseAuth = await msalInstance.acquireTokenSilent({ ...loginRequest, account })

      if (responseAuth.accessToken) {
        const decoded: any = decodeToken(responseAuth.accessToken)
        api.dispatch(
          setCredentials({
            user: {
              name: decoded?.given_name,
              username: responseAuth.account?.username,
              userId: decoded?.sub,
              imageUrl: `https://storageaccountsocial.blob.core.windows.net/avatars/${decoded?.sub}.png`,
            } as User,
            token: responseAuth.accessToken,
          })
        )
        result = await baseQuery(args, api, extraOptions)
      } else {
        msalInstance.logoutRedirect({ ...loginRequest }).then(() => {
          console.log('Logout')
        })
      }
    } else {
      msalInstance.logoutRedirect({ ...loginRequest }).then(() => {
        console.log('Logout')
      })
    }
  }

  return result
}
export const baseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  baseUrl: string
) => {
  const baseQuery = constructBaseQuery(baseUrl)
  let result = await baseQuery(args, api, extraOptions)
  return result
}
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))
