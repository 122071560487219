import { Attachment } from "../../../../resources/Attachment"
import { Sentiment } from "./Sentiment"
export enum Status {
        COMPLETED = 'completed',
        LISTED = 'listed',
      }
      
export interface LaunchpadResponseModel {
        id: string
        address: string
        authorId: string
        type: string
        content: string
        authorDescription: string
        description: string
        title: string
        status: Status
        //eventType: string     
        category: string
        author: string
        profileImagePath: string
        comments: number
        sentiment0: number
        sentiment1: number
        sentiment2: number
        sentiment3: number
        sentiment4: number
        privacy: string
        tags: string
        initDate: string
        finishDate: string
        initDateUnixDt: number
        finishDateUnixDt: number
        webSiteLink: string
        twitterLink: string
        discordLink: string
        facebookLink: string
        telegramLink: string
        instagramLink: string
        covers: Attachment[]
        previewImage: Attachment
        userSentiment: Sentiment
        auditedUrl?: string
        kycCompleted?: boolean
        network: string,
        price?: string,
        totalItems: number,
        gameId?: string,
        tokenSymbol: string;
        tokenName: string;
        totalSupply: number;
        decimals: number;
        tokenAddress: string;
}

