/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import QRcode from 'react-qr-code'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../hooks/hooks'

type Props = {
  className: string
  color: string
  headerColor: string
}

const DepositWidget: React.FC<Props> = ({className, headerColor, color}) => {

  const {t} = useTranslation("fusywallet")

  const selectedCurrency = useAppSelector((state) => state?.currency)
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${headerColor}`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bolder fs-3'>{t('DEPOSITWIDGET.TITLE')}</h3>
          </div>
          <div className='d-flex text-center flex-column text-white pt-8'>
            <br></br>

            <span className='fw-bold fs-7'>{selectedCurrency.selectedCurrency.public_key}</span>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
          style={{marginTop: '-100px', textAlign: 'center'}}
        >
          <div className='qrcode'>
            {' '}
            <QRcode
              value={
                selectedCurrency !== undefined ? selectedCurrency.selectedCurrency.public_key : ''
              }
              size={210}
              level='L'
            />
          </div>
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {DepositWidget}
