
import deMessages from './de.json';
import enMessages from './en.json';
import esMessages from './es.json';
import ptMessages from './pt.json';
import frMessages from './fr.json';
import jaMessages from './ja.json';
import zhMessages from './zh.json';

const resources = {
  de: { common: deMessages },
  en: { common: enMessages },
  es: { common: esMessages },
  fr: { common: frMessages },
  ja: { common: jaMessages },
  zh: { common: zhMessages },
  pt: { common: ptMessages },
};

export default resources