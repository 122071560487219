import React from 'react'
import {createWeb3Modal} from '@web3modal/wagmi/react'
import {defaultWagmiConfig} from '@web3modal/wagmi/react/config'
import {mainnet, polygon, taraxa, taraxaTestnet, sepolia} from 'wagmi/chains'
import {WagmiProvider} from 'wagmi'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {Chain} from 'wagmi/chains'
import { NETWORKS } from './Web3Context'

// 1. Your WalletConnect Cloud project ID
const projectId = '09f55428203399f0d330530366ec82e6'
const queryClient = new QueryClient()
// 2. Create wagmiConfig with additional chains
const metadata = {
  name: 'Fusyona',
  description: 'AppKit Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

// Map your networkData to an array of Chain objects
const mappedChains: Chain[] = NETWORKS.map((network) => ({
  id: network.chainId,
  name: network.name,
  network: network.name.toLowerCase().replace(' ', '-'),
  nativeCurrency: network.nativeCurrency,
  rpcUrls: {
    default: {http: [network.url]},
    public: {http: [network.url]},
  },
  blockExplorers: {
    default: {name: network.explorer.url, url: network.explorer.url},
  },
  iconUrl: network.logo,
})) as Chain[]

const myChains = mappedChains as [Chain, ...Chain[]]
const config = defaultWagmiConfig({
  chains: myChains,
  projectId,
  metadata,
  enableCoinbase: false,
  enableInjected: true,
})

createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
})

export function Web3ModalAppProvider({children}: {children: React.ReactNode}) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
