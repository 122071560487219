import React, { useEffect } from 'react';
import { notifySuccess } from '../../utils/toasts';

interface AddressComponentProps {
  address: string;
  className?: string;
  withCopy?: boolean;
}

const formatAddress = (address: string): string => {
  if (address.length < 4) {
    return 'Wrong address';
  }

  const firstEight = address.slice(0, 8);
  const lastEight = address.slice(-8);

  return `${firstEight}...${lastEight}`;
};

const ContractAddress: React.FC<AddressComponentProps> = ({ address, withCopy = false,className='' }) => {
  const formattedAddress = formatAddress(address);


  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    notifySuccess('Address copied to clipboard')
  };

  return (
    <div className={className}>
      <span onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
        {formattedAddress}
      </span>
      {withCopy && 
      <button
      className="copy-button"
      data-clipboard-text={address}
      style={{ display: 'none' }}
      >
        Copy
      </button>
      }
    </div>
  );
};

export default ContractAddress