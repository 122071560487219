import * as signalR from '@microsoft/signalr';

class SignalRService {
  private connection: signalR.HubConnection;
  private isConnected: boolean = false;

  constructor(hubUrl: string) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.connection.onclose((error) => {
      console.warn('Connection closed. Attempting to reconnect...', error);
      this.startConnection();
    });
  }

  async startConnection() {
    if (this.isConnected) return;
    try {
      console.debug('Starting SignalR connection...');
      await this.connection.start();
      console.debug('SignalR Connected.');
      this.isConnected = true;
    } catch (err) {
      console.error('Connection failed. Retrying...', err);
      setTimeout(() => this.startConnection(), 5000); // Retry
    }
  }

  async stopConnection() {
    if (!this.isConnected) return;
    try {
      await this.connection.stop();
      console.debug('SignalR Disconnected.');
      this.isConnected = false;
    } catch (err) {
      console.error('Error during disconnection:', err);
    }
  }

  on(event: string, handler: (...args: any[]) => void) {
    console.debug(`Subscribing to event: ${event}`);
    this.connection.on(event, handler);
  }

  off(event: string, handler: (...args: any[]) => void) {
    console.debug(`Unsubscribing from event: ${event}`);
    this.connection.off(event, handler);
  }

  async joinGroup(group: string) {
    if (this.isConnected) {
      try {
        console.debug(`Attempting to join group: ${group}`);
        await this.connection.send('joinGroup', group);
        console.debug(`Joined group: ${group}`);
      } catch (err) {
        console.error(`Failed to join group: ${group}`, err);
      }
    } else {
      console.warn('Cannot join group. Not connected to SignalR.');
    }
  }

  async leaveGroup(group: string) {
    if (this.isConnected) {
      try {
        console.debug(`Attempting to leave group: ${group}`);
        await this.connection.send('leaveGroup', group);
        console.debug(`Left group: ${group}`);
      } catch (err) {
        console.error(`Failed to leave group: ${group}`, err);
      }
    } else {
      console.warn('Cannot leave group. Not connected to SignalR.');
    }
  }
}

export const signalRService = new SignalRService('https://fusyhub.azurewebsites.net/node');