/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import { Currency } from '../tokenDeposit/DepositWizardHelper';
import { VerticalCreateWithdraw } from '../tokenWithdraw/VerticalCreateWithdraw';


type WithdrawWrapperParams = {
  currencyId: string;
}
const NewWithdrawWrapper: FC = () => {

  return (
    <>
      <VerticalCreateWithdraw currency={Currency.BRL} color='#212e48' />
    </>
  )
}

export default NewWithdrawWrapper;
