import { useEffect, useState } from 'react';
import { useGetPriceQuery } from '../modules/BondingCurve/services/launchpadService';
import { signalRService } from '../modules/BondingCurve/services/signalRService';
import { useWeb3Context } from '../contexts/Web3';

const useLivePrice = () => {
  const {network} = useWeb3Context();
  const { data: fetchedPrice, isLoading: isInitialPriceLoading, refetch } = useGetPriceQuery(network.priceCoin)
  const [price, setPrice] = useState<number | undefined>(fetchedPrice?.price);
  const [isPriceLoading, setPriceLoading] = useState<boolean>(isInitialPriceLoading);

  useEffect(() => {
    if (fetchedPrice) {
      console.debug('Initial Price Fetch:', fetchedPrice.price);
      setPrice(fetchedPrice.price);
      setPriceLoading(false);
    }
  }, [fetchedPrice]);

  useEffect(() => {
    const handlePriceUpdate = (message: string) => {
      console.debug('Price Update:', message);
      const parts = message.split(';');
      if (parts.length > 1 && parts[0] == network.priceCoin) {
        const pricePart = parts[1];
        console.debug("New price", pricePart)
        setPrice(+pricePart);
      }
    };

    signalRService.on('price', handlePriceUpdate);
    signalRService.startConnection().then(() => signalRService.joinGroup('FUSY2'));
    

    return () => {
      signalRService.off('price', handlePriceUpdate);
      signalRService.leaveGroup('FUSY2');
    };
  }, []);

  useEffect(() => {
    refetch()
  },[network])

  return { price, isPriceLoading };
};

export default useLivePrice;
