/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, lazy, Suspense } from "react";
import { Routes as RoutesDom, Route, Navigate } from "react-router-dom";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { ErrorsPage } from "../components/errors/ErrorsPage";
import ToastManager from "../components/ToastManager/ToastManager";
import { LaunchpadProjectOverview } from "../modules/BondingCurve/components/Launchpad/LaunchpadProjectOverview";
import { LaunchpadProjectSale } from "../modules/BondingCurve/components/Launchpad/LaunchpadProjectSale";
import { LaunchpadProjectAuthor } from "../modules/BondingCurve/components/Launchpad/LaunchpadProjectAuthor";
import { LaunchpadProjectAdmin } from "../modules/BondingCurve/components/Launchpad/LaunchpadProjectAdmin";
import AdvancedSearchWrapper from "../modules/BondingCurve/pages/AdvancedSearchWrapper";
import AdminDetail from "../modules/BondingCurve/pages/AdminDetail";
import { LaunchpadProjectLeaderboard } from "../modules/BondingCurve/components/Launchpad/LaunchpadProjectLeaderboard";
import AdminDashboard from "../modules/BondingCurve/pages/AdminDashboard";
import Callback from "../components/Callback/callback";
import DepositsWrapper from "../modules/FusyWallet/pages/deposits/DepositsWrapper";
import NewDepositWrapper from "../modules/FusyWallet/pages/newdeposit/NewDepositWrapper";
import NewWithdrawWrapper from "../modules/FusyWallet/pages/newwithdraw/NewWithdrawWrapper";
import WalletWrapper from "../modules/FusyWallet/pages/wallet/WalletWrapper";
import WithdrawalsWrapper from "../modules/FusyWallet/pages/withdrawals/WithdrawalsWrapper";
import { AuthorizedRoute } from "./AuthorizedRoute";
import AuthManager from "../components/AuthManager/AuthManager";
import { useAppSelector } from "../hooks/hooks";
import { isExpired } from "react-jwt";
// Lazy loaded routes
const CreateCurveWrapper = lazy(
  () => import("../modules/BondingCurve/pages/CreateCurveWrapper")
);
const BondingCurveExplorerWrapper = lazy(
  () => import("../modules/BondingCurve/pages/BondingCurveExplorerWrapper")
);
const CurveDetailWrapper = lazy(
  () => import("../modules/BondingCurve/pages/CurveDetailWrapper")
);
const CategoryExplorerWrapper = lazy(
  () => import("../modules/BondingCurve/pages/CategoryExplorerWrapper")
);

const Routes: FC = () => {
  const token = useAppSelector((state) => state.auth.token);
  const isAuthorized = token && !isExpired(token);

  console.debug("Auth", !!isAuthorized)
  return (
    <>
      <ToastManager />
      <AuthManager />
      <Suspense fallback={<></>}>
        <RoutesDom>
          <Route element={<MasterLayout />}>
            <Route path="/explore" element={<BondingCurveExplorerWrapper />} />
            <Route path="/search" element={<AdvancedSearchWrapper />} />
            <Route
              path="/explore/:category"
              element={<CategoryExplorerWrapper />}
            />
            <Route path="/dashboard" element={<AdminDashboard />} />

            <Route path="/detail/:launchpadId" element={<CurveDetailWrapper />}>
              <Route path="overview" element={<LaunchpadProjectOverview />} />
              <Route
                path="leaderboard"
                element={<LaunchpadProjectLeaderboard />}
              />
              <Route path="sale" element={<LaunchpadProjectSale />} />
              <Route path="author" element={<LaunchpadProjectAuthor />} />
              <Route path="admin" element={<LaunchpadProjectAdmin />} />
            </Route>
            <Route
              path="/detail/:launchpadId/adminPage/:gameId"
              element={<AdminDetail />}
            />
            <Route path="/new-bonding-curve" element={<CreateCurveWrapper />} />
            {!isAuthorized && (
              <Route
                path="/wallet"
                element={
                  <>
                    <Callback path="/wallet" />
                  </>
                }
              />
            )}
            {isAuthorized && (
              <>
                <Route
                  path="/wallet"
                  element={<WalletWrapper />}
                />
                <Route
                  path="/wallet/new-deposits"
                  element={<NewDepositWrapper />}
                /> 
                <Route
                  path="/wallet/new-withdrawals"
                  element={<NewWithdrawWrapper />}
                />
                <Route
                  path="/wallet/deposits"
                  element={<DepositsWrapper />}
                />
                <Route
                  path="/wallet/withdrawals"
                  element={<WithdrawalsWrapper />}
                />
              </>
            )}
          </Route>
          <Route
                path="/signin-oidc"
                element={
                  <>
                    <Callback />
                  </>
                }
              />
            <Route path='/' element={<Navigate to='/explore'/>} />
          {/* Catch-all 404 route */}
          <Route path="*" element={<ErrorsPage />} />
        </RoutesDom>
      </Suspense>
    </>
  );
};

export { Routes };
