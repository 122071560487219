/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BigNumber from 'big.js'
import numeral from 'numeral'
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { UserCurrency } from '../../../resources/wallet/UserCurrency';
import { useGetFullAddressQuery } from '../../../services/walletService';
import { KTSVG } from '../../../utils/KTSVG';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { setSelectedCurrency } from '../../../slices/currencySlice';

type Props = {
  className: string
  headerColor: string
  color: string
  title: string
}

const BalanceSelectorWidget: React.FC<Props> = ({className, headerColor, color, title}) => {
  const {t} = useTranslation("fusywallet")

  function calculateTotal(currency: UserCurrency) {
    const available = new BigNumber(currency.balance.available)
    const in_orders = new BigNumber(currency.balance.in_orders)
    const total = available.add(in_orders)
    const decimal = Math.min(8, currency.cryptocurrency.decimals)
    return (
      <>
        <p>
          {total.toFixed() !== '0'
            ? numeral(total.div(10 ** decimal).toFixed()).format('0,0.00[000000]')
            : '0.00000000'}
        </p>
      </>
    )
  }

  function calculateAvailable(currency: UserCurrency) {
    const available = new BigNumber(currency.balance.available)
    const decimal = Math.min(8, currency.cryptocurrency.decimals)

    return (
      <>
        <p>
          {available.toFixed() !== '0'
            ? numeral(available.div(10 ** decimal).toFixed()).format('0,0.00[000000]')
            : '0.00000000'}
        </p>
      </>
    )
  }

  function calculateInOrder(currency: UserCurrency) {
    const in_orders = new BigNumber(currency.balance.in_orders)
    const decimal = Math.min(8, currency.cryptocurrency.decimals)

    return (
      <>
        <p>
          {in_orders.toFixed() !== '0'
            ? numeral(in_orders.div(10 ** decimal).toFixed()).format('0,0.00[000000]')
            : '0.00000000'}
        </p>
      </>
    )
  }

  const dispatch = useAppDispatch()
  const selectedCurrency = useAppSelector((state) => state?.currency)

  const {data, isLoading, isSuccess} = useGetFullAddressQuery()

  // This function is triggered when the select changes
  const onCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCurrency = data?.list.find((item) => item.id === event.target.value)
    if (selectedCurrency !== undefined) {
      dispatch(setSelectedCurrency({selectedCurrency: selectedCurrency}))
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bolder fs-3'>{t('BALANCESELECTOR.HELP')} {title}:</h3>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8'>
            <select
              onChange={onCurrencyChange}
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={selectedCurrency.selectedCurrency.id}
            >
              {isLoading && 'Loading...'}
              {isSuccess &&
                data &&
                data.list.map((currency, i) => (
                  <option key={i} value={currency.id}>
                    {currency.cryptocurrency.label}({currency.cryptocurrency.symbol})
                  </option>
                ))}
            </select>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className={`shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-${color}`}
          style={{marginTop: '-100px'}}
        >
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                  {t('BALANCESELECTOR.TOTAL')}
                </a>
                <div className='text-gray-400 fw-bold fs-7'>{t('BALANCESELECTOR.BALANCE')}</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                  {calculateTotal(selectedCurrency?.selectedCurrency)}
                </div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <Link className='fs-5 text-gray-800 text-hover-primary fw-bolder' to={''}></Link>
                <div className='text-gray-400 fw-bold fs-7'>{t('BALANCESELECTOR.AVALIABLE')}</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                  {calculateAvailable(selectedCurrency?.selectedCurrency)}
                </div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/electronics/elc005.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <div className='text-gray-400 fw-bold fs-7'>{t('BALANCESELECTOR.IN_ORDER')}</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                  {calculateInOrder(selectedCurrency?.selectedCurrency)}
                </div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}

          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {BalanceSelectorWidget}
