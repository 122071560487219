/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {Form, Formik, FormikValues} from 'formik'

import { Step1 } from './steps/Step1'
import { Currency, IWithdraw, createWithdrawSchemas, inits } from './WithdrawWizardHelper'
import { useTranslation } from 'react-i18next'
import { ImageWrapper } from '../../resources/Models/ImageWrapper'
import { StepperComponent } from '../../components/Metronic/_StepperComponent'
import { KTSVG } from '../../utils/KTSVG'
import { useAppSelector } from '../../../../hooks/hooks'

type Props = {
  color: string,
  currency: Currency
}
const VerticalCreateWithdraw: React.FC<Props> = ({ color, currency}) => {

  const {t} = useTranslation("fusywallet");

  let selectedUser = useAppSelector((state) => state?.auth);
  let profileId = selectedUser.user.userId;

  const [files, setFiles] = useState<ImageWrapper[]>([]);

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createWithdrawSchemas[0])
  const [initValues] = useState<IWithdraw>(inits)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createWithdrawSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: IWithdraw, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createWithdrawSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {

    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        <div
          className={`d-flex justify-content-center  rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 bg-${color}`}
        >
          <div className='px-6 px-lg-10 px-xxl-15 py-20'>
            <div className='stepper-nav'>
              <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title'>{t('WITHDRAW.STEP1.TITLE')}</h3>
                  <div className='stepper-desc fw-bold'>{t('WITHDRAW.STEP1.DESCRIPTION')}</div>
                </div>

              </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`d-flex flex-row-fluid flex-center rounded bg-${color}`}>
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {({setFieldValue, values, validateForm}) => (
              <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 currency={currency}/>
                </div>

                <div className='d-flex flex-stack pt-10'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      {t('NEWNFT.BACK')}
                    </button>
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {/* {
                          (stepper.current?.currentStepIndex === undefined || stepper.current?.currentStepIndex === 0) &&
                            t('NEWNFT.CONTINUE')} */}
                        {/* {
                          stepper.current?.currentStepIndex === 0 &&
                            t('NEWNFT.SUBMIT')} */}
                        Withdraw
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export {VerticalCreateWithdraw}
