import * as Yup from 'yup'

export enum Currency {
  USD = "USD",
  CAD = "CAD",
  BRL = "BRL"
}
export enum PaymentMethod {
  PIX = "PIX",
  E_TRANSFER = "e-Transfer"
}

export interface IDeposit {
  currency: Currency;
  paymentMethod: PaymentMethod;
  value: number;
  contact: string;
  proofOfPayment: string;
}
const inits: IDeposit = {
  paymentMethod: PaymentMethod.PIX,
  currency: Currency.USD,
  value: 0,
  contact: '',
  proofOfPayment: ''
};

const createDepositSchemas = [

  Yup.object({
    currency: Yup.string().required().label("Currency is required"),
    paymentMethod: Yup.string().required().label("Payment Method  is required"),
    value: Yup.number().required().label("Value  is required"),
    contact: Yup.string()
    .matches(
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,4})|(\(\d{2,3}\) ?\d{1,4}))(-| )?\d{1,4}(-| )?\d{1,9}$|^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
      'Must be a valid email or phone number'
    )
    .required('Contact is required'),
  }),
  Yup.object({
    proofOfPayment: Yup.string().required().label("Proof of payment is required"),
  })

];

export {createDepositSchemas, inits}
