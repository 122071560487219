import {BaseQueryApi, createApi, FetchArgs} from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './apiService';
import { UserProfile } from '../resources/UserProfile';


export const profileService = createApi({
  reducerPath: 'profile',
  tagTypes: ['profile'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl =   'https://bktuserprofileapi.azurewebsites.net';
     return baseQueryWithReauth(args,api,extraOptions, baseUrl);
  },

  endpoints: (builder) => ({
    newLogin: builder.mutation<UserProfile, void>({
      query: () => ({
        url: `/users/newlogin`,
        method: 'POST',
      }),
    })
  }),
})
export const {
  useNewLoginMutation,
} = profileService

export const profileEndpoints = profileService.endpoints;