import React, {FC, useEffect, useState} from 'react'
import {Field, useFormikContext} from 'formik'
import {useDropzone} from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { IDeposit } from '../DepositWizardHelper'
import { ImageWrapper } from '../../../resources/Models/ImageWrapper'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as any,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#323248',
  borderStyle: 'dashed',
  backgroundColor: 'rgba(0,0,0,0)',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '250px',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const img = {
  display: 'block',
  width: '100%',
  height: '250px',
}

const Step2: FC<{
  handleFileChange: any
}> = ({handleFileChange}) => {
    const {t} = useTranslation("fusywallet")

  const [file, setFile] = useState<ImageWrapper[]>([]);

  const [fileStyle, setFileStyle] = useState<any | null>(null);

  const {  setFieldValue } = useFormikContext<IDeposit>()


  const dropFile = useDropzone({
    accept: ['.png', '.jpg', '.jpeg'],
    onDrop: (acceptedFiles: any) => {
      const fileInput = acceptedFiles.map(
        (file: any) =>
          ({
            file,
            url: URL.createObjectURL(file),
          } as ImageWrapper)
      )
      setFile(fileInput)
      console.log(fileInput);
      setFieldValue('proofOfPayment', fileInput[0]?.url)

      handleFileChange(fileInput)
    },
  })

  useEffect(() => {
    setFileStyle({
      style: {
        ...baseStyle,
        ...(dropFile?.isDragActive ? activeStyle : {}),
        ...(dropFile?.isDragAccept ? acceptStyle : {}),
        ...(dropFile?.isDragReject ? rejectStyle : {}),
      },
    })
  }, [dropFile?.isDragActive, dropFile?.isDragReject, dropFile?.isDragAccept])

  const thumbFile = file?.map((wrapper: {file: File; url: string | ArrayBuffer | null}) => (
    <div className={'thumb'} key={wrapper?.file?.name}>
      <div className={'thumbInner'}>
        {wrapper?.file?.type?.startsWith('image') && (
          <img src={`${wrapper?.url}`} style={img} alt='Thumbnail' />
        )}
      </div>
    </div>
  ))

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>{t('NEWNFT.STEP2.SUBTITLE')}</h2>

        
        <div className='text-gray-400 fw-bold fs-6'>
        {t('NEWNFT.STEPS.HELP_TEXT')}
         
          <a href='/dashboard' className='link-primary fw-bolder'>
          {t('NEWNFT.STEPS.HELP_LINK')}
          </a>
          .
        </div>
      </div>
      <div className='fv-row  w-100'>
        <div className='row w-100 d-flex justify-content-center'>
          <div className='w-100 mb-2'>
            <p>{t('NEWNFT.STEP2.FILE')}</p>
          </div>

          <Field type='hidden' name='proofOfPayment' />
          {fileStyle && (
            <div {...dropFile?.getRootProps(fileStyle)}>
              <input {...dropFile?.getInputProps()} name='dropFile' />
              <p>{t('NEWNFT.STEPS.DRAG_HELP')}</p>
            </div>
          )}
          {!!thumbFile && (
            <div className='col-lg-6 col-sm-12'>
              <aside className={'thumbsContainer'}>{thumbFile}</aside>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {Step2}
