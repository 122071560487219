import { LaunchpadResponseModel } from "../resources/launchpad/LaunchpadResponseModel";
import { MarketStatusResponse } from "@fusyona/erc20-launchpad/dist/src/zapp/types";
import { mapToBCLaunchpadItem } from "../utils";
import { LaunchpadType } from "../resources/BCLauchpadItem";
import { UniverseChainInfo } from "../../../resources/chains";
import { getFacade } from "./GetZappFunFacade";

export async function GetBCLaunchpadInfo(
  provider: any,
  model: LaunchpadResponseModel,
  network: UniverseChainInfo,
  priceUsd: number
): Promise<LaunchpadType> {
  console.debug("Get MarketStatus", model.tokenAddress, priceUsd);
  const facade = await getFacade(network, provider);
  const marketStatus: MarketStatusResponse = await facade.getMarketStatusV2({
    token: model.tokenAddress,
    nativeCoinPrice: priceUsd,
  });
  console.debug("marketStatus:", marketStatus);
  return mapToBCLaunchpadItem(marketStatus, model);
}

// run this multiple times by putting in its own function
async function GetBCLaunchpadListInfo(
  provider: any,
  models: LaunchpadResponseModel[],
  network: UniverseChainInfo,
  priceUsd: number
): Promise<LaunchpadType[]> {

  // Realiza solicitudes para cada launchpad
  const results = await Promise.allSettled(
    models.map((launchpad) =>
      GetBCLaunchpadInfo(provider, launchpad, network, priceUsd)
    )
  );

  // Extraer solo los resultados exitosos
  const successfulItems = results
    .filter((result) => result.status === "fulfilled")
    .map((result) => (result as PromiseFulfilledResult<LaunchpadType>).value);

  // Log de errores para solicitudes fallidas
  results
    .filter((result) => result.status === "rejected")
    .forEach((result) => {
      console.error(
        `Failed to fetch info for launchpad:`,
        (result as PromiseRejectedResult).reason
      );
    });

  return successfulItems;
}

export default GetBCLaunchpadListInfo;
