// PriceContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import useLivePrice from '../hooks/useLivePrice';

interface PriceContextValue {
  price: number | undefined;
  isPriceLoading: boolean;
}

const PriceContext = createContext<PriceContextValue | undefined>(undefined);

export const PriceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { price, isPriceLoading } = useLivePrice();

  return (
    <PriceContext.Provider value={{ price, isPriceLoading }}>
      {children}
    </PriceContext.Provider>
  );
};

export const usePrice = (): PriceContextValue => {
  const context = useContext(PriceContext);
  if (!context) {
    throw new Error('usePrice must be used within a PriceProvider');
  }
  return context;
};
