/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import { LaunchpadType } from '../../resources/BCLauchpadItem'
import ContractAddress from '../../../../components/ContractAddress'

type Props = {
  className: string
  launchpad: LaunchpadType
}

const LaunchpadInformation: React.FC<Props> = ({className, launchpad}) => {

  const [total, setTotal] = useState<string>('')
  const [lookupTime, setLookupTime] = useState(0)

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column pb-10 pb-lg-15'>
        <div className='flex-grow-1'>
          {/* begin::Link */}
          <a href='#' className='text-dark fw-bolder text-hover-primary fs-4'>
            Information
          </a>
          {/* end::Link */}

          {/* begin::Desc */}
          <div
            id='kt_table_widget_4_table_wrapper'
            className='dataTables_wrapper dt-bootstrap4 no-footer'
          >
            <div className='table-responsive'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer'
                id='kt_table_widget_4_table'
              >
                <tbody className='fw-bold text-gray-600'>
                  <tr className='odd'>
                    <td>Presale Address</td>
                    <td><ContractAddress address={launchpad.address}/></td>
                    
                  </tr>
                  <tr className='odd'>
                    <td>Token Address</td>
                    <td><ContractAddress address={launchpad.tokenAddress}/></td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
          {/* end::Desc */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {LaunchpadInformation}
