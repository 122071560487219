import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  newFeature?: boolean
}

const AsideMenuItem: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  newFeature = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  return (
    <div className='menu-item'>
      {!newFeature && (
        <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && (
            <span className='menu-icon'>
              <i className={clsx('bi fs-3', fontIcon)}></i>
            </span>
          )}
          <span className={`menu-title`}>{title}</span>
        </Link>
      )}
      {newFeature && (
        <div className={clsx('menu-link without-sub')}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && (
            <span className='menu-icon'>
              <i className={clsx('bi fs-3', fontIcon)}></i>
            </span>
          )}
          <span className={`menu-title ${newFeature && ' text-muted'}`}>{title}</span>
          {newFeature && <span className='badge badge-light-primary'>Soon</span>}
        </div>
      )}
      {children}
    </div>
  )
}

export {AsideMenuItem}
