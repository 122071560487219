import React, {useEffect, useState} from 'react'
import * as marked from 'marked'
import {useOutletContext} from 'react-router-dom'
import DOMPurify from 'dompurify'
import { useGetSingleLaunchpadByAddressOrIdQuery } from '../../services/launchpadService';
import { VideoComponent } from '../VideoComponent'
import { useLazyGetGameDetailsQuery } from '../../services/universeService'
import { LaunchpadType } from '../../resources/BCLauchpadItem';

type OutletContext = {
  launchpad: LaunchpadType
  className: string
}

marked.setOptions({
  gfm: true,
  breaks: true,
})

const LaunchpadProjectOverview: React.FC = () => {
  const {launchpad, className} = useOutletContext<OutletContext>()
  const [markdownHTML, setMarkdownHTML] = useState<string>('')
  const [getGameDetails, { data: game }] = useLazyGetGameDetailsQuery();
  const {data: launchpad_from_web2, isSuccess} = useGetSingleLaunchpadByAddressOrIdQuery(
    launchpad.id || ''
  )
  
  
  useEffect(() => {
    const fetchMarkdown = async () => {
      if (launchpad_from_web2?.description) {
        const rawHTML: string = await marked.parse(launchpad_from_web2?.description || '')

        const styledHTML = rawHTML.replace(
          /<iframe /g,
          '<iframe style="width: 100%; height: 500px;" '
        )

        // Sanitize the HTML, allowing only safe YouTube embeds
        const sanitizedHTML = DOMPurify.sanitize(styledHTML, {
          ALLOWED_TAGS: [
            'iframe',
            'p',
            'b',
            'i',
            'strong',
            'em',
            'a',
            'ul',
            'ol',
            'li',
            'blockquote',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
          ],
          ALLOWED_ATTR: [
            'src',
            'width',
            'height',
            'frameborder',
            'allow',
            'allowfullscreen',
            'style',
          ],
          ALLOWED_URI_REGEXP: /^https:\/\/www\.youtube\.com\/embed\//, // Only allow YouTube embeds
        })

        

        setMarkdownHTML(sanitizedHTML)
      }

      
    }
    if (launchpad.gameId)
        getGameDetails(launchpad.gameId)
  }, [launchpad])

  const markdownStyles = {
    marginBottom: '20px',
    lineHeight: '1.6',
    fontSize: '14px',
    color: '#d1d5db',
  }

  const headingStyles = {
    marginTop: '20px',
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#ffffff',
  }

  const listStyles = {
    paddingLeft: '20px',
    marginBottom: '15px',
  }

  const linkStyles = {
    color: '#3b82f6',
    textDecoration: 'underline',
  }

  const linkHoverStyles = {
    color: '#2563eb',
    textDecoration: 'none',
  }

  return (
    <div className={`${className}`}>
    <div className='card' style={{margin: '0', padding: '20px'}}>
      {/* Wrapper */}
      <div className="mb-8">
        {/* Info */}
        <div className="d-flex flex-wrap mb-6">
          
          
          
        </div>

        {/* Title */}
        <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold">
          {launchpad?.title || "Game Title Placeholder"}
         
        </a>

        {/* Video */}
        {game?.teaserVideo && (
          <div className="overlay mt-8">
            {/* Video Placeholder */}
            <VideoComponent src={game.teaserVideo} />
            {/* Subtitle */}

           

            
           
          </div>
        )}
      </div>

      {/* Description */}
      {launchpad?.description && (
        <div className="fs-5 fw-semibold text-gray-600">
          <div
            className="markdown-content"
            style={{ ...markdownStyles, margin: "0", padding: "10px" }}
            dangerouslySetInnerHTML={{ __html: markdownHTML }}
          />
          {launchpad?.description && (
          <div>
            <div
              className="markdown-content"
              style={{ ...markdownStyles, margin: '0', padding: '10px' }}
              dangerouslySetInnerHTML={{ __html: markdownHTML }}
            />
            <p>{launchpad.description}</p>
          </div>
        )}
        </div>
      )}
      <br></br>
      <br></br>
 <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
            <div className="d-flex flex-stack flex-grow-1">
            {/* Content */}
            <div className="fw-semibold">
              <h4 className="text-gray-900 fw-bold">Note</h4>
              <div className="fs-6 text-gray-700">
              This teaser showcases the gameplay experience. The game will only be available after the token graduates from the Bancor curve and is listed on the DEX.
              </div>
            </div>
            </div>
          </div>

    </div>
  </div>
    /*
    <div className={`${className}`}>
      <div className='card' style={{margin: '0', padding: '20px'}}>
      {game?.description && (
          <div>
            <div
              className="markdown-content"
              style={{ ...markdownStyles, margin: '0', padding: '10px' }}
              dangerouslySetInnerHTML={{ __html: markdownHTML }}
            />
            <p>{game.description}</p>
          </div>
        )}
        {game && <VideoComponent src={game.teaserVideo} />}
      </div>
   
    </div>
    */
  )
}

export {LaunchpadProjectOverview}
