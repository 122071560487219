import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { UniverseChainId } from '../../resources/chains'

export interface AuthState {
  user: User
  token: string
  network: UniverseChainId
}

export interface User {
  userId: string
  username: string
  name: string
  imageUrl: string
}

const chainIds = Object.values(UniverseChainId).filter((id) => typeof id === 'number') as number[]

const getNetwork = (network?: string | null): UniverseChainId => {
  if (network) {
    const chainId = parseInt(network, 10)
    if (!isNaN(chainId) && chainIds.includes(chainId)) {
      return chainId as UniverseChainId
    }
  }
  // Valor predeterminado: TaraxaTestnet
  const defaultChainId = UniverseChainId.TaraxaTestnet
  localStorage.setItem('network', defaultChainId.toString())
  return defaultChainId
}

const network = localStorage.getItem('network')

const initialState: AuthState = {
  user: {
    userId: '',
    username: '',
    name: '',
    imageUrl:
      'https://storageaccountsocial.blob.core.windows.net/236ae669-ff32-40b8-8a3d-35e2b93d1bbc/68ddbc6e-6b47-4ab1-a948-1671a48eeaa8.png',
  } as User,
  token: '',
  network: getNetwork(network),
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setNetwork: (state, {payload: {network}}: PayloadAction<{network: UniverseChainId}>) => {
      state.network = network
      localStorage.setItem('network', network.toString())
    },
    setCredentials: (
      state,
      {payload: {user, token}}: PayloadAction<{user: User; token: string}>
    ) => {
      state.user = user
      state.token = token
    },
  },
})

export const {setCredentials, setNetwork} = authSlice.actions
export default authSlice.reducer