/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {BalanceSelectorWidget} from '../deposits/components/BalanceSelectorWidget' //' /components/BalanceSelectorWidget'
import {WithdrawalWidget} from './components/WithdrawalWidget'
import { useTranslation } from 'react-i18next'

const WithdrawalPage: FC = () => {
  const {t} = useTranslation("fusywallet")


  return (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      {/* begin::Col */}
      <div className='col-xl-6'>
        <BalanceSelectorWidget
          className='card-xl-stretch mb-xl-8'
          headerColor='primary'
          color='#212e48'
          title={t('BALANCESELECTOR.TITLE')}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xl-6'>
        <WithdrawalWidget
          className='card-xl-stretch mb-5 mb-xl-8'
          chartColor='info'
          chartHeight='250px'
        />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>);
}

const WithdrawalsWrapper: FC = () => {
  const {t} = useTranslation("fusywallet")
  return (
    <>
      <WithdrawalPage />
    </>
  )
}

export default WithdrawalsWrapper;
