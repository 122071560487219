/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React, { FC } from "react";
import { useLayout } from "../../core";
import { DefaultTitle } from "../header/page-title/DefaultTitle";
import LanguageSelector from "../../../../components/LanguageSelector";
import { useAppSelector } from "../../../../hooks/hooks";
import { Link } from "react-router-dom";
import { checkIsActive, KTSVG } from "../../../helpers";
import { MenuItem } from "../header/MenuItem";
import { useWeb3Context } from "../../../../contexts/Web3";

const toolbarButtonMarginClass = "ms-1 ms-lg-3";
const Toolbar1: FC = () => {
  const { classes } = useLayout();
  const { isConnected } = useWeb3Context();
  const token = useAppSelector((state) => state.auth.token);
  const isAuthorized = Boolean(token);

  return (
    <div className="toolbar" id="kt_toolbar">
      {/* begin::Container */}
      <div
        id="kt_toolbar_container"
        className={clsx(
          classes.toolbarContainer.join(" "),
          "d-flex flex-stack"
        )}
      >
        <DefaultTitle />

        {/* begin::Actions */}
        <div className="d-flex align-items-center py-1">
          {/* begin::Wrapper */}
          {isConnected && (
            <MenuItem
              title={"My Account"}
              fontIcon="bi bi-briefcase"
              to="/dashboard"
              hasBullet={false}
            />
          )}
          {!isAuthorized && (
            <div
              className={clsx(
                "d-flex align-items-center",
                toolbarButtonMarginClass
              )}
            >
              <LanguageSelector />
            </div>
          )}
          {/* end::Button */}
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Toolbar1 };
