import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserCurrency} from '../resources/wallet/UserCurrency'

export interface SelectedCurrencyState {
  selectedCurrency: UserCurrency
}



//Initial state

const initialState: SelectedCurrencyState = {
  selectedCurrency: {
    id: '',
    cryptocurrency: {
      id: '',
      label: '',
      symbol: '',
      decimals: 0,
      profileURL: '',
      withdrawalFee: '',
      depositFee: '',
    },
    balance: {
      available: '',
      in_orders: '',
    },
    label: '',
    public_key: '',
    status: '',
    currency_fee: {
      id: '',
      label: '',
      profileURL: '',
      symbol: '',
      decimal: 0,
    },
  } as UserCurrency,
}



export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setSelectedCurrency: (
      state,
      { payload: { selectedCurrency } }: PayloadAction<{ selectedCurrency: UserCurrency }>
    ) => {
      state.selectedCurrency = selectedCurrency
    },
  },
})

export const {setSelectedCurrency} = currencySlice.actions
export default currencySlice.reducer

