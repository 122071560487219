import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useWeb3Context } from "../../../../contexts/Web3";
import { LaunchpadType } from "../../resources/BCLauchpadItem";
import TelegramBotHelp from "../TelegramBotHelp";
import { KTSVG } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { IAddTelegramBot } from "../../resources/game/missions";
import {
  useAddTelegramBotMutation,
  useAddTwitterUsernameMutation,
  useGetGameQuery,
} from "../../services/gameService";
import { notifyError, notifySuccess } from "../../../../utils/toasts";
import { GameResponseModel } from "../../resources/game/game";

type OutletContext = {
  launchpad: LaunchpadType;
  game?: GameResponseModel;
  className: string;
  ownerAddress: string;
};

const LaunchpadProjectAdmin: React.FC = () => {
  const { launchpad,game,  className, ownerAddress } =
    useOutletContext<OutletContext>();
  const { isConnected, accountAddress, signMessage } = useWeb3Context();
  const gameData = useGetGameQuery(launchpad.gameId!, {skip: !launchpad.gameId})
  const [addTelegramBot, { data, isLoading, isSuccess, isError }] =
    useAddTelegramBotMutation();
  const [addTwitterUsername, _] = useAddTwitterUsernameMutation();
  const authorized = useMemo(
    () => !isConnected || accountAddress != ownerAddress,
    [isConnected, accountAddress]
  );
  const [twitterUsername, setTwitterUsername] = useState<string>(gameData.data?.twitterUsername || "");
  const [editableTwitter, setEditableTwitter] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      botname: "",
      clientId: "",
      secretKey: "",
    },
    validationSchema: Yup.object({
      botname: Yup.string()
        .required("Telegram bot name is required"),
      secretKey: Yup.string().required("Secret key is required"),
      clientId: Yup.string().required("Client id is required"),
    }),
    onSubmit: async (values) => {
      if (launchpad.gameId) {
        try {
          const message = `Add telegram bot: ${values.botname}.`;
          const signature = await signMessage(message);

          console.log("Form Values:", values);
          const _values: IAddTelegramBot = {
            telegramBotClientId: values.clientId,
            telegramBotClientSecret: values.secretKey,
            telegramBotName: values.botname,
            ownerAddress: accountAddress,
            Signature: signature,
            SignedMessage: message,
            gameId: launchpad.gameId,
          };
          addTelegramBot(_values);
        } catch (e) {
          notifyError("Error adding Telegram bot");
        }
      }
      // Submit the form values here or handle them as needed
    },
  });

  useEffect(() => {
    if (isSuccess) {
      notifySuccess("Telegram bot added.");
    } else if (isError) {
      notifyError("Error adding telegram bot info.");
    }
  }, [isLoading]);

  useEffect(() => {
    if (gameData.isSuccess) setTwitterUsername(gameData.data.twitterUsername || "")
  }, [gameData.isSuccess])

  const submitNewUsername = async () => {
    if (launchpad.gameId) {
      try {
        const message = `Add/modify twitter username: ${twitterUsername}`;
        const signature = await signMessage(message);

        addTwitterUsername({
          twitterUsername,
          gameId: launchpad.gameId,
          Signature: signature,
          SignedMessage: message,
          ownerAddress: accountAddress,
        })
          .unwrap()
          .then(() => {
            notifySuccess("Twitter username modified.");
          })
          .catch((e) => {
            console.error(e);
            notifyError("Error on twitter username modification.");
          })
          .finally(() => setEditableTwitter(false));
      } catch (e) {
        notifyError("Error adding Telegram bot");
      }
    }
  };

  const handleTwitterButton = () => {
    if (editableTwitter) submitNewUsername();
    else setEditableTwitter(true);
  };

  return authorized ? (
    <div className={`${className}`}>
      <div className="card" style={{ margin: "0", padding: "20px" }}>
        <div className="justify-content-end d-flex">
          <Link
            to={`../adminPage/${launchpad.gameId}`}
            className="btn btn-secondary fw-bold fs-7 py-2 px-3 align-items-center d-flex"
            type="submit"
          >
            <KTSVG
              path="/media/icons/duotone/Code/Settings4.svg"
              className="svg-icon-2"
            />{" "}
            Missions
          </Link>
        </div>
        <div>
          <label htmlFor="twitterUsername" className="required form-label mb-3">
            Twitter Username
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              value={twitterUsername}
              onChange={(e) => setTwitterUsername(e.target.value)}
              className="form-control"
              id="twitterUsername"
              name="twitterUsername"
              placeholder="my_username"
              aria-label="Twitter username"
                disabled={!editableTwitter}
            />
            <div className="input-group-append">
              <button
                className={`btn  rounded-0 ${
                  editableTwitter ? "btn-success" : "btn-light-primary"
                }`}
                onClick={handleTwitterButton}
                type="button"
              >
                {!editableTwitter ? (
                  <KTSVG
                    path="/media/icons/duotone/General/Edit.svg"
                    className="svg-icon-2 me-0"
                  />
                ) : (
                  <KTSVG
                    path="/media/icons/duotone/Navigation/Check.svg"
                    className="svg-icon-2 me-0"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
        <hr />
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-5">
            <label htmlFor="botname" className="required form-label mb-3">
              Telegram Bot Name
            </label>
            <input
              type="text"
              id="botname"
              name="botname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.botname}
              placeholder="BotName"
              className={
                "form-control form-control-lg form-control-solid" +
                (formik.touched.botname && formik.errors.botname
                  ? "input-error"
                  : "")
              }
            />
            {formik.touched.botname && formik.errors.botname ? (
              <div className="error-message">{formik.errors.botname}</div>
            ) : null}
          </div>

          <div className="mb-5">
            <label htmlFor="clientId" className="required form-label mb-3">
              ClientId
            </label>
            <input
              type="password"
              id="clientId"
              name="clientId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.clientId}
              className={
                "form-control form-control-lg form-control-solid" +
                (formik.touched.clientId && formik.errors.clientId
                  ? "input-error"
                  : "")
              }
            />
            {formik.touched.clientId && formik.errors.clientId ? (
              <div className="error-message">{formik.errors.clientId}</div>
            ) : null}
          </div>

          <div className="mb-5">
            <label htmlFor="secretKey" className="required form-label mb-3">
              Secret Key
            </label>
            <input
              type="password"
              id="secretKey"
              name="secretKey"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.secretKey}
              className={
                "form-control form-control-lg form-control-solid" +
                (formik.touched.secretKey && formik.errors.secretKey
                  ? "input-error"
                  : "")
              }
            />
            {formik.touched.secretKey && formik.errors.secretKey ? (
              <div className="error-message">{formik.errors.secretKey}</div>
            ) : null}
          </div>

          <button
            className="btn bg-hover-success text-hover-white text-success bg-light-success fw-bold"
            type="submit"
          >
            Submit
          </button>
        </form>
        <div className="mt-4">
          {game && 
          <TelegramBotHelp webUrl={game.staticWebsiteUrl}/>
          }
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export { LaunchpadProjectAdmin };
