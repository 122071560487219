import { FC, useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { Leaderboard } from "../components/Details/Leaderboard";
import { useNavigate, useParams } from "react-router";
import CreateMissionForm from "../components/Admin/CreateMissionForm";
import { ListMissions } from "../components/Admin/ListMissions";
import MissionModal from "../components/Admin/MissionsModal";
import {
  useAddMissionMutation,
  useDeleteMissionMutation,
  useGetMissionsMutation,
  useUpdMissionMutation,
} from "../services/gameService";
import { Button } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import { useWeb3Context } from "../../../contexts/Web3";
import {
  IAddUpdMission,
  IDeleteMission,
  Mission,
} from "../resources/game/missions";
import { notifySuccess, notifyError } from "../../../utils/toasts";
import { actionUrls } from "../components/Admin/CreateMissionFormHelper";
import { useTranslation } from "react-i18next";

type QueryParams = {
  gameId: string;
  launchpadId: string;
};

const AdminDetail: FC = () => {
  const { gameId, launchpadId } = useParams<QueryParams>();
  const { accountAddress, signMessage, isConnected, connect } =
    useWeb3Context();
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [getMissions, { data, isLoading, isUninitialized }] =
    useGetMissionsMutation();
  const [updMission, updResponse] = useUpdMissionMutation();
  const [delMission, delResponse] = useDeleteMissionMutation();
  const [addMission, addResponse] = useAddMissionMutation();
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState<Mission>();
  const loading = useMemo(
    () =>
      updResponse.isLoading || delResponse.isLoading || addResponse.isLoading,
    [addResponse.isLoading, delResponse.isLoading, updResponse.isLoading]
  );
  if (!gameId) {
    return <></>;
  }

  const fetch = async () => {
    if (!isConnected) {
      return connect();
    }
    console.debug("AAAAAAAAA");
    const message = `Fetch missions`;
    const signature = await signMessage(message);
    getMissions({
      gameId: gameId!,
      page,
      pageSize: 10,
      ownerAddress: accountAddress,
      Signature: signature,
      SignedMessage: message,
    });
  };

  const handleSubmit = async (values: Mission) => {
    console.log("Form values", values);
    const isUpd = !!editData?.missionId;

    const message = `${isUpd ? "Update" : "Add"} Mission: ${values.title}`;
    const signature = await signMessage(message);
    const _values: IAddUpdMission = {
      ...values,
      metadata: {
        ...values.metadata,
        ...actionUrls[values.metadata.actionTag],
      },
      gameId: gameId,
      ownerAddress: accountAddress,
      Signature: signature,
      SignedMessage: message,
    };
    const action = isUpd ? updMission(_values) : addMission(_values);
    await action
      .unwrap()
      .then(() => handleSuccess(isUpd))
      .catch(() => handleError(isUpd));
  };

  const handleSuccess = (isUpd: boolean) => {
    notifySuccess(`Mission ${isUpd ? "Updated" : "Added"}`);
    handleModalClose();
  };

  const handleError = (isUpd: boolean) => {
    notifyError(`Error ${isUpd ? "updating" : "adding"} mission`);
  };

  const handleDelete = async (index: number) => {
    if (data && data.missions.length > index) {
      const mission = data.missions[index];
      const message = `Delete Mission: ${mission.title}`;
      const signature = await signMessage(message);
      const _values: IDeleteMission = {
        gameId: gameId,
        ownerAddress: accountAddress,
        Signature: signature,
        SignedMessage: message,
        missionId: mission.missionId!,
      };
      delMission(_values)
        .unwrap()
        .then(() => notifySuccess("Mission deleted."))
        .catch(() => notifyError("Error deleting mission."));
    }
  };

  const handleEdit = (index: number) => {
    if (data && data.missions.length > index) {
      setEditData(data.missions[index]);
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false)  
    setEditData(undefined)
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {t('PAGE.MISSIONS')}
      </PageTitle>
      <div className="d-flex justify-content-end align-items-center mb-5">
        <Button variant="primary" onClick={() => setShowModal(true)}>
          <KTSVG
            path={"/media/icons/duotone/Code/Plus.svg"}
            className={"svg-icon-1 rotate-180"}
          />{" "}
          Add Mission
        </Button>

        {!isUninitialized && (
          <Button variant="secondary" onClick={fetch} className="ms-2">
            Refresh list
          </Button>
        )}
      </div>
      <ListMissions
        missions={data?.missions}
        isLoading={isLoading}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        disableActions={isLoading}
      />
      {isUninitialized && (
        <Button className="mt-2" variant="primary" onClick={fetch}>
          Get missions list
        </Button>
      )}
      <MissionModal
        handleFormSubmit={handleSubmit}
        show={showModal}
        handleClose={handleModalClose}
        data={editData}
      />
    </>
  );
};

export default AdminDetail;
