import {createApi, FetchArgs} from '@reduxjs/toolkit/query/react'
import {BaseQueryApi} from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {baseQuery} from '../../../services/apiService'
import baseUrls from '../../../services/baseUrls'
import { GameResponseModel } from '../resources/game/game'
import { IAddUpdMission, IAddTelegramBot, IAddTwitterUsername, IDeleteMission, IMissionsRequest, Mission, PaginatedGameMissions } from '../resources/game/missions'

export const gameService = createApi({
  reducerPath: 'game',
  tagTypes: ['game'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.games 
    return baseQuery(args, api, extraOptions, baseUrl)
  },

  endpoints: (builder) => ({
    getGame: builder.query<GameResponseModel, string>({
      query: (gameId) => ({
        url: `/games/${gameId}`,
        method: 'GET',
      }),
      providesTags: ['game'],
    }),
    getMissions: builder.mutation<PaginatedGameMissions, IMissionsRequest>({
      query: ({gameId, page, ...params}) => ({
        url: `/Missions/get-missions/${gameId}`,
        method: 'POST',
        body: {
          pageNumber: page,
          ...params
        }
      }),
    }),
    addMission: builder.mutation<any, IAddUpdMission>({
      query: ({gameId, ...body}) => ({
        url: `/Missions/add-new/${gameId}`,
        method: "POST",
        body
      })
    }),
    updMission: builder.mutation<any, IAddUpdMission>({
      query: ({missionId, ...body}) => ({
        url: `/Missions/update-details/${missionId}`,
        method: "PUT",
        body
      })
    }),
    deleteMission: builder.mutation<any, IDeleteMission>({
      query: ({missionId, ...body}) => ({
        url: `/Missions/delete/${missionId}`,
        method: "DELETE",
        body
      })
    }),
    addTelegramBot: builder.mutation<any, IAddTelegramBot>({
      query: ({gameId, ...body}) => ({
        url: `/add-telegram-bot/${gameId}`,
        method: "POST",
        body
      })
    }),
    addTwitterUsername: builder.mutation<any, IAddTwitterUsername>({
      query: ({gameId, ...body}) => ({
        url: `/add-twitter-username/${gameId}`,
        method: "POST",
        body
      })
    })
  }), 
})
export const {
    useLazyGetGameQuery,
    useGetGameQuery,
    useGetMissionsMutation,
    useAddMissionMutation,
    useAddTelegramBotMutation,
    useAddTwitterUsernameMutation,
    useDeleteMissionMutation,
    useUpdMissionMutation
} = gameService
