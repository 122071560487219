/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useNavigate } from 'react-router'
import { TransferFeeResponse } from '../../../resources/Models/TransferFeeResponse'
import { useAnswerPaymentMutation } from '../../../services/paymentService'
import { useTranslation } from 'react-i18next'

const PayFeeModal: FC<{response: TransferFeeResponse | null | undefined}> = ({response}) => {
    console.log("Payment Link");
    console.log(response?.payment?.rejectedLink);
    console.log(response?.payment?.approvedLink)
  const [
    pay, // This is the mutation trigger
    {data: paymentRespose,  isLoading, isSuccess, isError}, // This is the destructured mutation result
  ] = useAnswerPaymentMutation();

  const navigate = useNavigate();

  const {t} = useTranslation("fusywallet")


  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (isError) {
      MySwal.fire({
        title: <strong>{t(paymentRespose?.title || 'Oops')}</strong>,
        html: <i>{t(paymentRespose?.message)}</i>,
        icon: 'error',
        background: "#202124",
        confirmButtonColor: "#4BB543"
      }).then(() => {
        return navigate("/wallet");
      });
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      MySwal.fire({
        title: <strong>{t("congratulations")}</strong>,
        html: <><i>{t("processSuccess")}</i> <br></br><i>{t("processWait")}</i></>,
        icon: 'success',
        background: "#202124",
        confirmButtonColor: "#4BB543"
      }).then(() => {
        const a = document.getElementById("payment-modal-x-button");
        a?.click();
        return navigate("/wallet");
      });
    }
  }, [isSuccess])

  return (
    <>
      <div className='modal fade' tabIndex={-1} role="dialog" id='kt-modal-payment' aria-hidden='true'>
        <div className='modal-dialog modal-modal-lg' role="document">
          <div className='modal-content rounded'>
            <div className='modal-header justify-content-end border-0 pb-0'>
              <a id="payment-modal-x-button" className='text-muted' data-dismiss='modal' data-bs-dismiss='modal'>
                X
              </a>
            </div>

            <div className='modal-body flex-column pt-0 pb-15 px-5 px-xl-20'>
              <div className='mb-13 text-center'>
                <h3 className='mb-3'>Payment Details</h3>

                <div className='text-muted fw-bold fs-5'>
                  If you need more info, please check{' '}
                  <a href='#' className='link-primary fw-bolder'>
                    Pricing Guidelines
                  </a>
                  .
                </div>
              </div>
              {(!response || isLoading) && (
                <div className='d-flex justify-content-center w-100'>
                  <div
                    className='spinner-border'
                    style={{width: '3rem', height: '3rem'}}
                    role='status'
                  >
                  </div>
                </div>
              )}{' '}
              {(response && !isLoading) && (
                <>
                  <div className='d-flex justify-content-between w-100 mt-5 mb-5'>
                    <span>{response?.currency}</span>
                    <span>{response?.price}</span>
                  </div>

                  <div className='d-flex justify-content-center flex-row-fluid pt-12 w-100'>
                    <button
                      onClick={() => pay(response?.payment?.rejectedLink)}
                      type='reset'
                      className='btn btn-bordered-white mr-3'
                      data-bs-dismiss='modal'
                      data-dismiss='modal'
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => pay(response?.payment?.approvedLink)}
                      type='submit'
                      className='btn btn-primary'
                    >
                      Accept
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {PayFeeModal}
