import { createApi, FetchArgs } from '@reduxjs/toolkit/query/react'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { baseQuery } from '../../../services/apiService'
import baseUrls from '../../../services/baseUrls'
import { IGameInstance, IGameInstanceCategory, IPaginatedGame } from '../resources/universe/Games'
import { ILeaderboardRequest, PlayerProgress } from '../resources/game/leaderboard'

export const universeService = createApi({
  reducerPath: 'universe',
  tagTypes: ['universe'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.universe
    return baseQuery(args, api, extraOptions, baseUrl)
  },

  endpoints: (builder) => ({

    getGames: builder.query<IPaginatedGame, { pageNumber?: number, pageSize?: number }>({
      query: ({ pageNumber = 1, pageSize = 4 }) => ({
        url: `/api/games/all?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        method: 'GET',
      }),
      providesTags: ['universe'],
    }),
    getGameDetails: builder.query<IGameInstance, string>({
      query: (gameId) => ({
        url: `/api/games/gameinstance/${gameId}/game`,
        method: 'GET',
      }),
      providesTags: ['universe'],
    }),
    getGameCategories: builder.query<IGameInstanceCategory[], void>({
      query: () => ({
        url: '/Games/categories',
        method: 'GET',
      }),
      providesTags: ['universe'],
    }),
    getLeaderboard: builder.query<PlayerProgress[], ILeaderboardRequest>({
      query: ({ page, ...params }) => ({
        url: '/api/games/leaderboard',
        method: 'GET',
        params: { ...params, pageNumber: page }
      })
    }),

  }),
})
export const {
  useGetGamesQuery,
  useGetGameDetailsQuery,
  useLazyGetGameDetailsQuery,
  useGetGameCategoriesQuery,
  useGetLeaderboardQuery,
} = universeService
