import { ZappFun } from "@fusyona/erc20-launchpad";
import { AmmFactory } from "@fusyona/erc20-launchpad/dist/src/amm/AmmFactory";
import { UniverseChainInfo } from "../../../resources/chains";
import { BrowserProvider, JsonRpcProvider } from "ethers";
import AmmFactoryNoWallet from "@fusyona/erc20-launchpad/dist/src/amm/AmmFactoryNoWallet";

// Obtener la fachada de ZappFun para las transacciones
export const getFacade = async (
  network: UniverseChainInfo,
  provider: JsonRpcProvider | BrowserProvider
) => {
  let factoryAmm = await getFactoryAmm(provider);
  return new ZappFun(network.chainId, provider, factoryAmm);
};

export const getFactoryAmm = async (
  provider: JsonRpcProvider | BrowserProvider
) => {
  let factoryAmm;
  if (provider instanceof BrowserProvider) {
    factoryAmm = await AmmFactory.fromProvider(provider);
  } else {
    factoryAmm = await AmmFactoryNoWallet.fromProvider(provider);
  }
  return factoryAmm as AmmFactory
};
