import {BaseQueryApi, createApi, FetchArgs} from '@reduxjs/toolkit/query/react';
import {ClaimTokenRequest} from '../resources/ClaimTokenRequest'
import {Invoice} from '../resources/wallet/Invoice'
import {OrderHistoryItem} from '../resources/wallet/OrderHistoryItem'
import { baseQueryWithReauth } from '../../../services/apiService';

function convertClaimGiftRequest(code: string) {
  const bodyFormData = new FormData()
  bodyFormData.append('Code', code)
  return bodyFormData
}

export const paymentService = createApi({
  reducerPath: 'payments',
  tagTypes: ['payments'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl =   '';
     return baseQueryWithReauth(args,api,extraOptions, baseUrl);
  },
  
  endpoints: (builder) => ({
    answerPayment: builder.mutation<any, string>({
      query: (url: string) => ({
        url: `${url}`,
        method: 'POST',
      }),
    }),
    claimToken: builder.mutation<string, ClaimTokenRequest>({
      query: (request) => ({
        url: `https://fusy-pay.azurewebsites.net/api/${request.tokenId}/claim`, //`https://api.fusyona.com/pay/v1/${request.tokenId}/claim`,  //`http://localhost:7075/api/${request.tokenId}/claim`,
        method: 'POST',
        body: convertClaimGiftRequest(request?.code),
      }),
    }),
    getInvoices: builder.query<Invoice[], void>({
      query: () => ({
        url: `https://fusy-pay.azurewebsites.net/api/invoices`,
        method: 'GET',
      }),
    }),
    getOrders: builder.query<OrderHistoryItem[], void>({
      query: () => ({
        url: `https://fusy-pay.azurewebsites.net/api/orders`,
        method: 'GET',
      }),
    }),
  }),
})
export const {useAnswerPaymentMutation, useClaimTokenMutation, useGetInvoicesQuery, useGetOrdersQuery} =
  paymentService