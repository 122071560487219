/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BigNumber from 'big.js'
import numeral from 'numeral'
import { Invoice } from '../../../../resources/wallet/Invoice'
import { useGetInvoicesQuery } from '../../../../services/paymentService'
import { KTSVG } from '../../../../utils/KTSVG'
import { useTranslation } from 'react-i18next';
type Props = {
  className: string
}

const TableWidgetInvoices: React.FC<Props> = ({className}) => {

  const {t} = useTranslation("fusywallet")


   function calculateAmount(invoice: Invoice) {
     const total = new BigNumber(invoice.Amount)
     const decimal = Math.min(8, invoice.Decimals)
     return (
       <>
         <p>
           {total.toFixed() !== '0'
             ? numeral(total.div(10 ** decimal).toFixed()).format('0,0.00[000000]')
             : '0.00000000'}
         </p>
       </>
     )
   }

  const {data, isLoading, isSuccess} = useGetInvoicesQuery()

 

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{t('WALLET.INVOICES.RECENT_ORDERS')}</span>
          <span className='text-muted mt-1 fw-bold fs-7'>{t('WALLET.INVOICES.OVER_500_ORDERS')}</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 2 */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>{t('WALLET.INVOICES.QUICK_ACTIONS')}</div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mb-3 opacity-75'></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
                {t('WALLET.INVOICES.NEW_TICKET')}
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
                {t('WALLET.INVOICES.NEW_COSTUMER')}
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div
              className='menu-item px-3'
              data-kt-menu-trigger='hover'
              data-kt-menu-placement='right-start'
              data-kt-menu-flip='left-start, top'
            >
              {/* begin::Menu item */}
              <a href='#' className='menu-link px-3'>
                <span className='menu-title'>{t('WALLET.INVOICES.NEW_GROUP')}</span>
                <span className='menu-arrow'></span>
              </a>
              {/* end::Menu item */}
              {/* begin::Menu sub */}
              <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    {t('WALLET.INVOICES.ADMIN_GROUP')}
                  </a>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    {t('WALLET.INVOICES.STAFF_GROUP')}
                  </a>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    {t('WALLET.INVOICES.MEMBER_GROUP')}
                  </a>
                </div>
                {/* end::Menu item */}
              </div>
              {/* end::Menu sub */}
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
               {t('WALLET.INVOICES.NEW_CONTACT')}
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mt-3 opacity-75'></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content px-3 py-3'>
                <a className='btn btn-primary btn-sm px-4' href='#'>
                  {t('WALLET.INVOICES.GENERATE_REPORTS')}
                </a>
              </div>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu 2 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>{t('WALLET.INVOICES.TABLE.CURRENCY')}</th>
                <th className='min-w-140px'>{t('WALLET.INVOICES.TABLE.USER')}</th>
                <th className='min-w-120px'>{t('WALLET.INVOICES.TABLE.DATE')}</th>
                <th className='min-w-120px'>{t('WALLET.INVOICES.TABLE.TOTAL')}</th>
                <th className='min-w-120px'>{t('WALLET.INVOICES.TABLE.STATUS')}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {isLoading && 'Loading...'}
              {isSuccess &&
                data &&
                data.map((invoice, i) => (
                  <tr key={i}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-13-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <img src={invoice.CurrencyImagePath} className='' alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                            {invoice.CurrencyName}
                          </a>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {invoice.CurrencySymbol}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {invoice.UserEmail}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {invoice.CreationDate}
                      </a>
                    </td>
                    <td className='text-dark fw-bolder text-hover-primary fs-6'>
                      {calculateAmount(invoice)}
                    </td>
                    <td>
                      <span className='badge badge-light-success'>{t('WALLET.INVOICES.COMPLETED')}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {TableWidgetInvoices}
