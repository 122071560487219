import React from "react";
import clsx from "clsx";
import NetworkSelector from "../../../../components/NetworkSelector";
import LanguageSelector from "../../../../components/LanguageSelector";
import { useAppSelector } from "../../../../hooks/hooks";
import SearchHandler from "../../../../components/SearchHandler";
import { useLazySearchQuery } from "../../../../modules/BondingCurve/services/launchpadService";
import { ListResponse } from "../../../../resources/ListResponse";
import { LaunchpadResponseModel } from "../../../../modules/BondingCurve/resources/launchpad/LaunchpadResponseModel";
import { LaunchpadSearchItem } from "../../../../modules/BondingCurve/components/Launchpad/LaunchpadSearchItem";
import { useLayout } from "../../core";
import { KTSVG } from "../../../helpers";

const toolbarButtonMarginClass = "ms-1 ms-lg-3";

const Topbar: React.FC = () => {
  const { config } = useLayout();

  const renderSearchResult = (
    data: ListResponse<any>,
    closeModal: () => any
  ) => {
    return (
      <div>
        {data.data.map((d: LaunchpadResponseModel) => (
          <LaunchpadSearchItem
            launchpad={d}
            className="pb-2"
            onClick={closeModal}
          />
        ))}
      </div>
    );
  };

  const [fetchSearchData, { data }] = useLazySearchQuery();

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        <SearchHandler
          fetchData={(params) => fetchSearchData({ ...params }).unwrap()}
          renderResult={renderSearchResult}
        />
      </div>  
      <div className="d-none d-md-flex flex-wrap-1">
          <div
            className={clsx(
              "d-flex align-items-center",
              toolbarButtonMarginClass
            )}
          >
            <w3m-button />
          </div>
          <div
            className={clsx(
              "d-flex align-items-center",
              toolbarButtonMarginClass
            )}
          >
            <NetworkSelector />
          </div>
        </div>
      {config.header.left === "menu" && (
        <div
          className="d-flex align-items-center d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-30px h-md-30px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className="svg-icon-1"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Topbar;
