/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import { VerticalCreateDeposit } from '../tokenDeposit/VerticalCreateDeposit';


const NewDepositWrapper: FC = () => {
  return (
    <>
      <VerticalCreateDeposit color='#212e48' />
    </>
  )
}

export default NewDepositWrapper;
