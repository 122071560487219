
import * as Yup from "yup";
import { actionTags, Mission } from "../../resources/game/missions";

// Dropdown options
export const frequencyOptions = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
];

export const actionTagOptions = [
  { value: actionTags.FollowX, label: "Follow Twitter" },
  { value: actionTags.ValidateUsername, label: "Validate Username" },
];

export const actionUrls: Record<actionTags, { apiUrl: string, logoUrl: string }> = {
  [actionTags.FollowX]: { 
    apiUrl: 'https://fusyverse-dmf3hxccahcgeca2.centralus-01.azurewebsites.net/twitter/follow-principal',   
    logoUrl: "https://storageaccountsocial.blob.core.windows.net/minigames/x-logo.png" 
  },
  [actionTags.ValidateUsername]: { 
    apiUrl: 'https://fusyverse-dmf3hxccahcgeca2.centralus-01.azurewebsites.net/twitter/validate-username', 
    logoUrl: "https://storageaccountsocial.blob.core.windows.net/minigames/x-logo.png" 
  }
}

export const initialValues: Mission = {
  title: "",
  description: "",
  type: "social",
  reward: {
    coins: 0
  },
  metadata: {
    actionTag: actionTags.FollowX,
    targetQuantity: 1,
    isComplete: false,
    missionButtonText: "",
    objectiveLines: 0,
    ...actionUrls[actionTags.FollowX],
  },
  userId: undefined,
  frequency: "",
  requirements: {},
}

// Validation Schema
export const missionSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  frequency: Yup.string().required("Frequency is required"),
  requirements: Yup.object().shape({
    coin: Yup.string()
  }).required("Requirements are required"),
  metadata: Yup.object().shape({
    actionTag: Yup.string()
      .oneOf(["FollowTwitter", "ValidateUsername"])
      .required("Action Tag is required"),
    targetQuantity: Yup.number()
      .required("Target Quantity is required")
      .positive("Must be a positive number"),
    missionButtonText: Yup.string().required("Mission button text is required"),
  }),
});