import { css } from '@emotion/react'
import React from 'react'
import { ClipLoader } from 'react-spinners'
import './Loading.scss'

const CustomLoading: React.FC<{ size?: number, className?: string, text?: string  }> = ({ size = 150, className = "", text }) => {
    return (
      <span className={'Loading ' + className} data-testid='Loading'>
        <ClipLoader color='#FFF' loading size={size} />  {text && <span className='ms-2'>{text}</span>}
      </span>
    )
  }
export { CustomLoading }

const Loading: React.FC = () => {
  return (
    <div className={'Loading'} data-testid='Loading'>
      <ClipLoader color='#FFF' loading size={150} />
    </div>
  )
}

export default Loading
