/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import {createTransactionSchemas, ICreateTransaction, inits} from './CreateTransactionHelper'

import BigNumber from 'big.js'
import {PayFeeModal} from './PayFeeModal'
import { useTranslation } from 'react-i18next'
import { useTransferMutation } from '../../../services/walletService'

import { TransferRequest } from '../../../resources/Models/TransferRequest'
import { useAppSelector } from '../../../../../hooks/hooks'


type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const WithdrawalWidget: React.FC<Props> = ({className, chartColor, chartHeight}) => {

  const {t} = useTranslation("fusywallet")

  const [
   transfer, // This is the mutation trigger
   {data: paymentRespose, isLoading}, // This is the destructured mutation result
 ] = useTransferMutation()

 const selectedCurrency = useAppSelector((state) => state?.currency)

 useEffect(() => {
   if (isLoading) {
     const button = document.getElementById('kt-trigger-modal-payment')
     button?.click()
   }
 }, [isLoading])

   const [currentSchema, setCurrentSchema] = useState(createTransactionSchemas[0])
   const [initValues] = useState<ICreateTransaction>(inits)

   const submitStep = (
     values: ICreateTransaction,
     actions: FormikValues
   ) => {
     setCurrentSchema(createTransactionSchemas[1])

     let decimals = selectedCurrency?.selectedCurrency.cryptocurrency.decimals
     let amount = BigNumber(values?.amount)
       .mul(10 ** decimals)
       .toFixed()

     console.log('subimeteu')
     console.log(`${selectedCurrency?.selectedCurrency.id}`)
     console.log(`${amount}`)
     console.log(`${values?.to}`)
     const request = {
       address: selectedCurrency?.selectedCurrency.id,
       to: values?.to,
       amount: amount,
     } as TransferRequest
     transfer(request)
     actions.resetForm({})
     
   }

  return (
    <>
    <a
        href='#'
        id='kt-trigger-modal-payment'
        className='d-none'
        data-bs-toggle='modal'
        data-bs-target='#kt-modal-payment'
      >
        Upgrade
      </a>
      <PayFeeModal response={paymentRespose}></PayFeeModal>
    <div className={`card ${className}`}>
      
      {/* <SuccessWithdrawalModal></SuccessWithdrawalModal>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {t('WITHDRAWALWIDGET.TITLE')}
          </span>
        </h3>
      </div>
      <div className='card-body d-flex flex-column'>
        <div className='pt-5'>
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {({setFieldValue, values, validateForm, resetForm}) => (
              <Form className='' noValidate id='kt_create_account_form'>
                <div style={{marginTop: '-25px'}}>
                  <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder={t('WITHDRAWALWIDGET.FORM.AMOUNT')}
                    name='amount'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='amount' />
                  </div>

                  <br></br>
                  <br></br>
                  <br></br>
                  <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder={t(
                      'WITHDRAWALWIDGET.FORM.RECIPIENT_ADDRESS',
                    )}
                    name='to'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='to' />
                  </div>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
                <div className='pt-5'>
                  <p className='text-center fs-6 pb-5 '>
                    <span className='badge badge-light-danger fs-8'>
                      {t('WITHDRAWALWIDGET.NOTES')}:
                    </span>
                    &nbsp; {t('WITHDRAWALWIDGET.CURRENT')}
                    {t('WITHDRAWALWIDGET.HELP1')}
                    <br />
                    {t('WITHDRAWALWIDGET.HELP2')}
                  </p>
                  <br />
                  <button
                    type='submit'
                    className={`btn btn-${chartColor} w-100 py-3`}
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_withdrawal'
                  >
                    {t('WITHDRAWALWIDGET.SEND')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* end::Body */}
    </div>
    </>
  )
}

export {WithdrawalWidget}

