/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {BalanceSelectorWidget} from './components/BalanceSelectorWidget'
import {DepositWidget} from './components/DepositWidget'

const DepositsPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      {/* begin::Col */}
      <div className='col-xl-8'>
        <BalanceSelectorWidget
          className='card-xl-stretch mb-xl-8'
          headerColor='primary'
          color='#212e48'
          title='deposit'
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xl-4'>
        <DepositWidget
          className='card-xl-stretch mb-5 mb-xl-8'
          headerColor='success'
          color='#212e48'
        />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
)

const DepositsWrapper: FC = () => {
  return (
    <>
      <DepositsPage />
    </>
  )
}

export default DepositsWrapper;
