import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/ja';
import '@formatjs/intl-relativetimeformat/locale-data/zh';
import {merge} from "lodash";
import commonMessages from "./common";
import fusyWalletMessages from "./fusywallet";

const resources = merge(commonMessages, fusyWalletMessages)

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Idioma padrão
    fallbackLng: 'en', // Idioma de fallback
    ns: ["common", "fusywallet"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // React já escapa os valores por padrão
    },
  });

export const changeLanguage = (lng: string) => { i18n.changeLanguage(lng); };

export default i18n;
