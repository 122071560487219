/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
import {useMsalAuthentication} from '@azure/msal-react';
import {InteractionType, RedirectRequest} from '@azure/msal-browser';
import {loginRequest} from '../../authConfig';

class CallBackProps {
path?: string
}

const Callback: React.FC<CallBackProps> = ({path = "/signin-oidc"}) => {
  const request: RedirectRequest = {
    ...loginRequest,
    redirectStartPage:  path,
  } as RedirectRequest;
  const {result} = useMsalAuthentication(InteractionType.Redirect, request);
  return (
    <>
    </>
  );
};
export default Callback;
