/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {TableWidgetWallet} from '../wallet/components/widgets/TableWidgetWallet'
import {TableWidgetInvoices} from '../wallet/components/widgets/TableWidgetInvoices'
import {TableWidgetOrders} from '../wallet/components/widgets/TableWidgetOrders'
import { useTranslation } from 'react-i18next';

const WalletPage: FC = () => (
  
  <>
    <TableWidgetWallet className='mb-5 mb-xl-8' />
  </>
)

const InvoicesPage: FC = () => (
  <>
    <TableWidgetInvoices className='mb-5 mb-xl-8' />
  </>
)

const OrdersPage: FC = () => (
  <>
    <TableWidgetOrders className='mb-5 mb-xl-8' />
  </>
)

const WalletWrapper: FC = () => {
  
  const {t} = useTranslation("fusywallet")
  return (
    <>
      <div className={`card card-xxl-stretch-50 mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{t('WALLET.TITLE')}</span>
            
          </h3>
          <div className='card-toolbar'>
            <ul className='nav'>
              <li className='nav-item'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_5_tab_1'
                >
                  {t('WALLET.BALANCE')}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_5_tab_2'
                >
                  {t('WALLET.INVOICES')}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_5_tab_3'
                >
                  {t('WALLET.ORDERS')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div className='tab-content'>
            {/* begin::Tap pane */}
            <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <WalletPage />
              </div>
              {/* end::Table */}
            </div>
            {/* end::Tap pane */}
            {/* begin::Tap pane */}
            <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <InvoicesPage/>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Tap pane */}
            {/* begin::Tap pane */}
            <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <OrdersPage/>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Tap pane */}
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export default WalletWrapper;
