/* eslint-disable react-hooks/exhaustive-deps */
import {useMsal} from '@azure/msal-react';
import React, {useEffect} from 'react';

import {decodeToken, isExpired} from 'react-jwt';

import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {setCredentials, User} from '../../setup/slices/authSlice';
import {loginRequest} from '../../authConfig';

const AuthManager: React.FC<{ children?: React.ReactNode }> = ({children}) => {
     const { instance, accounts, inProgress } = useMsal();
     const token = useAppSelector((state) => state.auth.token);
     const dispatch = useAppDispatch();

     useEffect(() => {
       const expired = isExpired(token);
      console.debug("aaaa", accounts, token)
       if (inProgress === 'none' && accounts?.length > 0 && accounts[0] && (!token || expired)) {
         // Retrieve an access token
      console.debug("aaaa", "adquiresilent")
         instance.acquireTokenSilent({
          account: accounts[0],
           ...loginRequest,
         }).then((response) => {
           if (response.accessToken) {
             const decoded: any = decodeToken(response.accessToken);
             console.log("SET_CREDENTIALS");
             console.debug("aaaa", "SET_CREDENTIALS")
             dispatch(
               setCredentials({
                 user: {
                   name: decoded?.given_name,
                   username: response.account?.username,
                   userId: decoded?.sub,
                   imageUrl: `https://storageaccountsocial.blob.core.windows.net/avatars/${decoded?.sub}.png`,
                 } as User,
                 token: response.accessToken,
               })
             )
           }
         }).catch((error) => {
          console.debug("aaaa", "ERROR")
             instance.logoutRedirect({ ...loginRequest }).then(() => {
              console.log("Logout");
            });
           
         });
       }
     }, [inProgress, accounts, instance, token]);

     return (
       <div data-testid="AuthManager">
         {children}
       </div>
     );
   };

export default AuthManager;
