import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import {
  actionTagOptions,
  actionUrls,
  frequencyOptions,
  initialValues,
  missionSchema,
} from "./CreateMissionFormHelper";
import CustomStaticSelect from "../../../../components/CustomStaticSelect/CustomStaticSelect";
import { Mission } from "../../resources/game/missions";

type Props = {
  data: Mission,
};
// Form Component
const CreateMissionForm: React.FC<Props> = ({data}) => {
  const { values, errors, touched, isSubmitting, setFieldValue, setValues } = useFormikContext<Mission>();

  useEffect(() => {
      setValues(data)
  }, [data.missionId])

  return (
    <Form className="row">
      <div className="col-md-6">
        <h5>Basic Information</h5>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <Field
            name="title"
            type="text"
            className={`form-control ${
              errors.title && touched.title ? "is-invalid" : ""
            }`}
          />
          {errors.title && touched.title && (
            <div className="invalid-feedback">{errors.title}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <Field
            as="textarea"
            name="description"
            className={`form-control ${
              errors.description && touched.description ? "is-invalid" : ""
            }`}
          />
          {errors.description && touched.description && (
            <div className="invalid-feedback">{errors.description}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="frequency" className="form-label">
            Frequency
          </label>
          <CustomStaticSelect
            options={frequencyOptions}
            initialIndex={0}
            onChange={(option) => setFieldValue("frequency", option?.value)}
            className={`${
              errors.frequency && touched.frequency ? "is-invalid" : ""
            }`}
          />
          {errors.frequency && touched.frequency && (
            <div className="invalid-feedback d-block">{errors.frequency}</div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="reward" className="form-label">
            Reward in coins
          </label>
          <Field
            name="reward.coin"
            type="number"
            className={`form-control ${
              errors.reward?.coin && touched.reward?.coin ? "is-invalid" : ""
            }`}
          />
          {errors.description && touched.description && (
            <div className="invalid-feedback">{errors.description}</div>
          )}
        </div>
      </div>
      <div className="col-md-6 mb-3">
        <h5>Metadata for social mission</h5>
        <div className="mb-3">
          <label htmlFor="actionTag" className="form-label">
            Action Tag
          </label>
          <CustomStaticSelect
            id="actionTag"
            options={actionTagOptions}
            initialIndex={0}
            onChange={(option) =>
              setFieldValue("Metadata.actionTag", option?.value)
            }
            className="react-select"
            classNamePrefix="select"
          />
          <ErrorMessage
            name="Metadata.actionTag"
            component="div"
            className="text-danger"
          />
        </div>

        {/* Target Quantity */}
        <div className="mb-3">
          <label htmlFor="targetQuantity" className="form-label">
            Target Quantity
          </label>
          <Field
            type="number"
            id="metadata.targetQuantity"
            name="metadata.targetQuantity"
            className={`form-control ${
              errors.metadata?.targetQuantity &&
              touched.metadata?.targetQuantity
                ? "is-invalid"
                : ""
            }`}
            placeholder="The number of actions required (e.g., number of follows)."
          />
          <ErrorMessage
            name="metadata.targetQuantity"
            component="div"
            className="text-danger"
          />
        </div>

        {/* Mission Button Text */}
        <div className="mb-3">
          <label htmlFor="missionButtonText" className="form-label">
            Mission Button Text
          </label>
          <Field
            type="text"
            id="missionButtonText"
            name="metadata.missionButtonText"
            className={`form-control ${
              errors.metadata?.missionButtonText &&
              touched.metadata?.missionButtonText
                ? "is-invalid"
                : ""
            }`}
            placeholder='A label for the action button (e.g., "Follow Now" or "Validate Now")'
          />
          <ErrorMessage
            name="metadata.missionButtonText"
            component="div"
            className="text-danger"
          />
        </div>
      </div>

      {/* Other Metadata fields (targetQuantity, missionButtonText, logoUrl, etc.) */}

      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
        {values.missionId ? "Save Changes" : "Submit"}
      </button>
    </Form>
  );
};

export default CreateMissionForm;
