/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useTranslation } from 'react-i18next';
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideBuyInfo} from './AsideBuyInfo'

export function AsideMenuMain() {
  const { t } = useTranslation();

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {t('ASIDEMENU.SERVICES')}
          </span>
        </div>
      </div>
      
      


      <AsideMenuItemWithSub
        to='/explore/pages/1'
        title={t('ASIDEMENU.EXPLORE')}
        icon='/media/icons/duotune/general/gen002.svg'
      >
       
        <AsideMenuItem
          to='/explore/pages/1'
          title={t('ASIDEMENU.EXPLORE')}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>


      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
          <div className='d-flex flex-wrap fw-bold justify-content-center gap-2 fs-6 mb-4'>

            <i
              onClick={() => {
                window.open('https://twitter.com/FusySwap', '_blank')
              }}
              className='bi bi-twitter fs-1 text-white'
            ></i>

            <i
              onClick={() => {
                window.open('https://discord.com/invite/5bSrzgXEAJ', '_blank')
              }}
              className='bi bi-discord fs-1 text-white'
            ></i>
          
          </div>
        </div>
        <div
          className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6'
          id='kt_app_sidebar_footer'
        >
          <a
            href='https://fusyapp.gitbook.io/fusyapp/'
            className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            data-bs-original-title='200+ in-house components and 3rd-party plugins'
            data-kt-initialized='1'
          >
            <span className='btn-label'>
              <i className='fas fa-book'></i> Docs
            </span>
          </a>
        </div>
      </div>
    </>
  )
}
