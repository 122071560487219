import React, { ReactNode } from "react";
import { useLang } from "./i18nContext";
import { I18nextProvider } from "react-i18next";
import i18n, { changeLanguage } from "./i18n";


interface Props {
  children?: ReactNode;
}
const I18nProvider: React.FC<Props> = (props) => {
  const locale = useLang();
  changeLanguage(locale);

  return <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>;
};

export { I18nProvider };
