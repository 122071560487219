import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDebounce } from "../../_metronic/helpers";
import useSearch from "../../hooks/useSearch";
import { Link, useLocation } from "react-router-dom";

interface SearchHandlerProps {
  fetchData: (params: {query: string, page: number, pageSize: number}) => Promise<any>; // A generic fetch function for the search API
  renderResult: (data: any, closeModal: () => any) => JSX.Element; // A render function that takes the search result and returns the JSX
}

const SearchHandler: React.FC<SearchHandlerProps> = ({ fetchData, renderResult }) => {
  const location = useLocation()
  // State for managing search modal visibility
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Close the search modal and reset state
  const closeModal = () => {
    setShowModal(false);
    setSearchQuery("");
  };

  const { data, loading, error, resultContent } = useSearch({
    page: 1,
    pageSize: 7,
    query: searchQuery,
    fetchData,
    renderResult: (data) => renderResult(data, closeModal),
  });

  // Open the search modal
  const openModal = () => setShowModal(true);

  // Handles the search input
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Trigger the search when user presses "Enter" or submits the form
  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Optionally handle immediate search on submit
  };

  useEffect(() => {
    if (showModal) {
      setShowModal(false)
    }
  },[location.pathname])

  return (
    <div>
      {/* Toggle Button */}
      <div className="d-flex align-items-center">
      <button 
      type="button" 
      onClick={openModal}
      className="btn btn-light rounded-circle d-flex align-items-center justify-content-center" 
      style={{ width: '40px', height: '40px' }}
    >
      <i className="bi bi-search p-0" style={{ fontSize: '14px' }}></i>
    </button>
      </div>

      {/* Search Modal (Content follows your HTML structure) */}
      <Modal show={showModal} onHide={closeModal} className="mt-15">
        <Modal.Header className="py-4">
          {/* Search Form */}
          <form onSubmit={handleSearchSubmit} className="w-100 position-relative" autoComplete="off">
            <span className="position-absolute top-50 translate-middle-y ps-3">
              <i className="bi bi-search"></i>
            </span>
            <input
              type="text"
              className="form-control form-control-flush ms-10"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchInput}
            />

            {/* Spinner (Loading indicator) */}
            {loading ? (
              <span className="position-absolute top-50 end-0 translate-middle-y lh-0 me-1">
                <span className="spinner-border h-15px w-15px align-middle text-gray-500"></span>
              </span>
            ) : <button className=" btn btn-secondary position-absolute top-50 end-0 translate-middle-y px-3 py-2" onClick={closeModal}>
            <i className="bi bi-x-lg p-0 pb-1"></i>
          </button>}
            
          </form>
        </Modal.Header>

        <Modal.Body className="py-5">
          {/* Display search results */}
          {resultContent}

          {/* Empty State */}
          {error && !loading && (
            <div className="text-center d-block">
              <p>Error: {error.message}</p>
            </div>
          )}

          {/* Empty State */}
          {!data && !loading && (
            <div className="text-center d-block">
              <p>No results found.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Link to={'/search'} className='btn btn-secondary' >Advanced Search</Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SearchHandler;
