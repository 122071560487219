import {BaseQueryApi, createApi, FetchArgs} from '@reduxjs/toolkit/query/react';
import {Balance} from '../resources/wallet/Balance';
import {CriptoCurrency} from '../resources/wallet/CriptoCurrency';
import {TransferRequest} from '../resources/Models/TransferRequest';
import {Currencyfee} from '../resources/wallet/Currencyfee';
import {FullCurrenciesList} from '../resources/wallet/FullCurrenciesList'
import {TransferFeeResponse} from '../resources/Models/TransferFeeResponse';
import { baseQueryWithReauth } from '../../../services/apiService';


export const walletService = createApi({
  reducerPath: 'wallet',
  tagTypes: ['wallet'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = 'https://wallet-api-dev.azurewebsites.net/api'
    return baseQueryWithReauth(args, api, extraOptions, baseUrl)
  },

  endpoints: (builder) => ({
    getCurrencyBalance: builder.query<Balance[], string>({
      query: (address: string) => ({
        url: `addresses/${address}/balance`,
        method: 'GET',
      }),
    }),
    getCurrencyFee: builder.query<Currencyfee, string>({
      query: (address: string) => ({
        url: `cryptocurrencies/${address}/fees`,
        method: 'GET',
      }),
    }),
    getCryptocurrencies: builder.query<{list: CriptoCurrency[]}, void>({
      query: () => ({
        url: 'cryptocurrencies',
        method: 'GET',
      }),
    }),
    getFullAddress: builder.query<FullCurrenciesList, void>({
      query: () => ({
        url: 'fulladdresses',
        method: 'GET',
      }),
    }),
    getFullAddressPaginated: builder.query<FullCurrenciesList, number>({
      query: (page) => ({
        url: `fulladdressespaginated?page=${page}`,
        method: 'GET',
      }),
    }),
    transfer: builder.mutation<TransferFeeResponse, TransferRequest>({
      query: (request) => ({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `addresses/${request?.address}/transfer/${request?.to}/${request?.amount}`,
        method: 'POST',
      }),
    }),
  }),
})
export const {
  useGetCurrencyBalanceQuery,
  useGetCurrencyFeeQuery,
  useGetCryptocurrenciesQuery,
  useGetFullAddressQuery,
  useGetFullAddressPaginatedQuery,
  useTransferMutation,
} = walletService