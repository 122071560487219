// NetworkSelector.tsx
import React, {useEffect, useState} from 'react'
import {setNetwork} from '../../setup/slices/authSlice'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSwitchChain} from 'wagmi'
import { useWeb3Context } from '../../contexts/Web3'
import { useAppDispatch } from '../../hooks/hooks'
import { UniverseChainId, chainInfo } from '../../resources/chains'
import CustomStaticSelect, { OptionType } from '../CustomStaticSelect/CustomStaticSelect'
import { NETWORKS } from '../../contexts/Web3/Web3Context'

const NetworkSelector: React.FC = () => {
  const {network} = useWeb3Context()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // Crear opciones para CustomStaticSelect
  const options: OptionType[] = NETWORKS.map((net) => ({
    label: net.name,
    value: net.chainId,
    image: net.logo || '', // Asegúrate de que 'logoURI' existe en 'net'
  }))

  // Encontrar el índice de la red actual
  const currentNetworkIndex = NETWORKS.findIndex((n) => n.name === network.name)
  const initialIndex = currentNetworkIndex !== -1 ? currentNetworkIndex : 0

  const [selectedNetwork, setSelectedNetwork] = useState(NETWORKS[initialIndex])

  useEffect(() => {
    if (currentNetworkIndex === -1) {
      dispatch(setNetwork({network: NETWORKS[0].chainId as UniverseChainId}))
    }
  }, [currentNetworkIndex, dispatch, NETWORKS])

  useEffect(() => {
    dispatch(setNetwork({network: selectedNetwork.chainId as UniverseChainId}))
    if (location.pathname.startsWith('/collections/')) {
      navigate('/collections')
    }
  }, [selectedNetwork, dispatch, navigate, location.pathname])

  const {chains, error, switchChain} = useSwitchChain()

  const handleNetworkChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      const newNetwork = NETWORKS.find((n) => n.name === selectedOption.label)
      if (newNetwork) {
        setSelectedNetwork(newNetwork)
        if (switchChain) {
          switchChain({chainId: newNetwork.chainId})
        }
      }
    }
  }

  return (
    <div className='d-flex align-items-center' style={{minWidth: '150px'}}>
      <CustomStaticSelect
        initialIndex={initialIndex}
        options={options}
        onChange={handleNetworkChange}
        containerMinWidth='150px'
        imageBorderRadius='4px'
      />
    </div>
  )
}

export default NetworkSelector
