/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {Currency, IDeposit, PaymentMethod} from '../DepositWizardHelper'
import { ActionMeta, GroupBase, OptionProps, SingleValueProps, components } from 'react-select'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { KTSVG } from '../../../utils/KTSVG'


interface CurrencyOptionType {
  currency: Currency;
  flag: string;
  label: string;
}

interface PaymentOptionType {
  paymentMethod: PaymentMethod;
  icon: string;
  label: string;
  description: string;

}

const currencies: CurrencyOptionType[]  = [
  {
    currency: Currency.USD,
    flag: '/media/flags/united-states.svg',
    label: 'USD - Dólar Americano'
  },
  {
    currency: Currency.CAD,
    flag: '/media/flags/canada.svg',
    label: 'CAD - Dólar Canadense'
  },
  {
    currency: Currency.BRL,
    flag: '/media/flags/brazil.svg',
    label: 'BRL - Real Brasileiro'

  },
]

const paymentOptions: PaymentOptionType[] = [
  { 
    paymentMethod: PaymentMethod.PIX, 
    label: 'PIX - Brasil', 
    description: 'Pix is an instant payment platform created and managed by the Central Bank of Brazil, which enables the quick execution of payments and transfers[^1^][6].',
    icon: '/media/icons/duotune/finance/fin001.svg' 
  },
  { 
    paymentMethod: PaymentMethod.E_TRANSFER, 
    label: 'E-Transfer - Canadá', 
    description: 'Interac e-Transfer is a fast and convenient way to send or receive money within the security of your online or mobile banking service in Canada[^2^][1].',
    icon: '/media/icons/duotune/finance/fin001.svg'
  },
  // Adicione mais opções de pagamento aqui
];

const CurrencyOption : React.FC<OptionProps<CurrencyOptionType, false>> = ({ data, ...props }) => (
    <components.Option data={data} {...props}>
      <KTSVG path={data?.flag} className={'symbol symbol-20px me-4'} />
      {data?.label}
    </components.Option>
);
const CurrencySingleValue: React.FC<SingleValueProps<CurrencyOptionType, false, GroupBase<CurrencyOptionType>>> = ({ data, ...props }) => (
  <components.SingleValue data={data} {...props}>
    <KTSVG path={data?.flag} className={'symbol symbol-20px me-4'} />
    {data?.label}
  </components.SingleValue>
);

const PaymentOption : React.FC<OptionProps<PaymentOptionType, false>> = ({ data, ...props }) => (
  <components.Option data={data} {...props}>
    <div className='d-flex'>
      <div  className='d-flex flex-column justify-content-center align-items-center'>
        <KTSVG path={data?.icon} svgClassName={'symbol-50px'} className={'symbol symbol-50px me-4'} />
      </div>
    <div className='d-flex flex-column'>
      <span className='fw-bold'>{data?.label} </span>
      <span>{data?.description}</span>
    </div>
    
    </div>
  </components.Option>
);
const PaymentSingleValue: React.FC<SingleValueProps<PaymentOptionType, false, GroupBase<PaymentOptionType>>> = ({ data, ...props }) => (
<components.SingleValue data={data} {...props}>
  <KTSVG path={data?.icon} className={'symbol symbol-20px me-4'} />
  {data?.label}
</components.SingleValue>
);

const Step1: FC = () => {
  const {t} = useTranslation("fusywallet")
  const { values, setFieldValue } = useFormikContext<IDeposit>()
  const [selectedFlagOption, setSelectedFlagOption] = useState<CurrencyOptionType | null>(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOptionType | null>(null);

  const handleChangeFlag = (option: CurrencyOptionType | null, actionMeta: ActionMeta<CurrencyOptionType>) => {
    setFieldValue("currency", option?.currency);
    setSelectedFlagOption(option)
    console.log(option);
  };

  const handleChangePayment = (option: PaymentOptionType | null, actionMeta: ActionMeta<PaymentOptionType>) => {
    setFieldValue("paymentMethod", option?.paymentMethod);
    setSelectedPaymentOption(option)
    console.log(option);
  };
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          {t('DEPOSIT.STEP1.SUBTITLE')}
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          {t('NEWNFT.STEPS.HELP_TEXT')}
          <a href='/dashboard' className='link-primary fw-bolder'>
            {t('NEWNFT.STEPS.HELP_LINK')}
          </a>
          .
        </div>
      </div>

      <div className='mb-5 fv-row'>
        <div className='row'>
          <label className='form-label mb-1'>Currency</label>
          <Field type='hidden' name='currency' />

          <Select<CurrencyOptionType, false>
           value={selectedFlagOption} 
           options={currencies}
           components={{ Option: CurrencyOption , SingleValue: CurrencySingleValue}}
           onChange={handleChangeFlag}
           getOptionValue={(option) => option.currency}
           />


          <div className='text-danger mt-2'>
            <ErrorMessage name='currency' />
          </div>
        </div>
      </div>
      <div className='mb-5 fv-row'>
        <div className='row'>
          <label className='form-label mb-1'>Value</label>

          <Field type='number' className='form-control form-control-lg w-100' name='value' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='value' />
          </div>
        </div>
      </div>
      <div className='mb-5 fv-row'>
        <div className='row'>
          <label className='form-label mb-1'>Payment Method</label>

          <Field type='hidden' name='paymentMethod' />

          <Select<PaymentOptionType, false>
          value={selectedPaymentOption} 
          options={paymentOptions}
          components={{ Option: PaymentOption , SingleValue: PaymentSingleValue}}
          onChange={handleChangePayment}
          getOptionValue={(option) => option.paymentMethod}
          />

          <div className='text-danger mt-2'>
            <ErrorMessage name='currency' />
          </div>
        </div>
      </div>
      <div className='mb-5 fv-row'>
        <div className='row'>
          <label className='form-label mb-1'>
            E-mail or Phone Number
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          </label>

          <Field type='text' className='form-control form-control-lg' name='contact' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='contact' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
